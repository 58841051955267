import { CURRENT_VIEW } from "../_types/navType";

const initialState = {
  currentView: "",
};

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    default:
      return { ...state };
  }
}
