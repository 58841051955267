import {
  ISLOGGED,
  USER,
  TOKEN,
  ACCOUNT_CONFIRMED,
  RES_ERROR,
  GET_CODE_RESET,
} from "../_types/authType";

const initialState = {
  isLogged: false,
  user: "",
  accountConfirmed: false,
  token: "",
  resError: { isError: "false", msg: "" },
  sentcode: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ISLOGGED:
      return { ...state, isLogged: action.payload };
    case USER:
      return { ...state, user: action.payload };
    case ACCOUNT_CONFIRMED:
      return { ...state, accountConfirmed: action.payload };
    case TOKEN:
      return { ...state, token: action.payload };
    case RES_ERROR:
      return { ...state, resError: action.payload };
    case GET_CODE_RESET:
      return { ...state, sentcode: action.payload };
    default:
      return { ...state };
  }
}
