import { React, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
//import { useSelector } from "react-redux";
import LayoutMain from "../LayoutMain";
import { useDispatch } from "react-redux";
import AuthContext from "../../context/AuthContext";
import NavContext from "../../context/NavContext";
import { MENU_OPTION } from "../../util/directory";
import LoaderPage from "../../components/LoaderPage";
//import Button from "../../components/Button";

export default function Profile() {
  let navigate = useNavigate();
  const [profileListOriginal, setProfileListOriginal] = useState([]);
  const dispatch = useDispatch();
  const { setView } = useContext(NavContext);
  const [renderPage, setRenderPage] = useState(false);
  const { isLogged, user } = useContext(AuthContext);

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  const getData = () => {
    //dispatch(getPreregistros(chain));
  };

  useEffect(() => {
    if (isLogged) {
      //console.log("user", user);
      setRenderPage(true);
    }
  }, [isLogged]);

  useEffect(() => {
    if (renderPage && isLogged) {
      getData();
      setView(MENU_OPTION.PROFILE);
      setRenderPage(true);
    }
  }, [renderPage]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setNav(MENU_OPTION.LOGIN);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <LayoutMain>
      <div className="container-body">
        <div className="content-me">
          <div className="me">
            <p className="name">Nombre</p>
            <p className="value">{user?.attributes?.name}</p>
            <p className="name">Teléfono</p>
            <p className="value">{user?.attributes?.phone_number}</p>
            <p className="name">Email</p>
            <p className="value">{user?.attributes?.email}</p>
          </div>
        </div>
        {/*<div className="editMe">
          <Button color="red">Solicitar actualización de datos</Button>
  </div>*/}
      </div>
    </LayoutMain>
  );
}
