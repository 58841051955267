import {
  CONTACT_TYPES,
  REGIONES_GENERALES,
  REGIONES_ESPECIFICAS,
  NATURALEZA_SANGRADO,
  TRATAMIENTO,
  ESQUEMA,
  ARTROPATIA,
  ASEGURADORA,
  DIAGNOSTICOS,
  DIRECCION,
  ENTIDAD_FEDERATIVA,
  ESCOLARIDAD,
  ESTATUS_SS,
  INSTITUCIONES,
  HOSPITAL,
  PARENTESCO,
  PROFESIONAL_SALUD,
  SEVERIDAD,
  TIPO_SANGRE,
  COAGULOPATIA,
  UMF,
  UPLOADED_DIRECTORIES,
  REQ_DIRECTORIES,
  CURRENT_CATALOG,
  CATALOG_CREATED,
  CATALOG_EDITED,
  SHOW_TEXT,
  RESPONSE_ERROR,
} from "../_types/catalogsType";

const initialState = {
  contactTypes: [],
  regionesGenerales: [],
  regionesEspecificas: [],
  naturalezaSangrado: [],
  tratamiento: [],
  esquema: [],
  artropatia: [],
  aseguradora: [],
  diagnosticos: [],
  direccion: [],
  entidadFederativa: [],
  escolaridad: [],
  estatusSS: [],
  instituciones: [],
  hospital: [],
  parentesco: [],
  profesionalSalud: [],
  severidad: [],
  tipoSangre: [],
  coagulopatia: [],
  umf: [],
  uploadedDirectories: 0, //0-Sin cargar, 1-Cargando, 2-Cargaron exitosamente, 3-Cargaron con errores
  reqDirectories: false,
  currentCatalog: "",
  catalogCreated: false,
  catalogEdited: false,
  showText: "",
  responseError: false,
};

export default function catalogsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT_TYPES:
      return { ...state, contactTypes: action.payload };
    case REGIONES_GENERALES:
      return { ...state, regionesGenerales: action.payload };
    case REGIONES_ESPECIFICAS:
      return { ...state, regionesEspecificas: action.payload };
    case NATURALEZA_SANGRADO:
      return { ...state, naturalezaSangrado: action.payload };
    case TRATAMIENTO:
      return { ...state, tratamiento: action.payload };
    case ESQUEMA:
      return { ...state, esquema: action.payload };
    case ARTROPATIA:
      return { ...state, artropatia: action.payload };
    case ASEGURADORA:
      return { ...state, aseguradora: action.payload };
    case DIAGNOSTICOS:
      return { ...state, diagnosticos: action.payload };
    case DIRECCION:
      return { ...state, direccion: action.payload };
    case ENTIDAD_FEDERATIVA:
      return { ...state, entidadFederativa: action.payload };
    case ESCOLARIDAD:
      return { ...state, escolaridad: action.payload };
    case ESTATUS_SS:
      return { ...state, estatusSS: action.payload };
    case INSTITUCIONES:
      return { ...state, instituciones: action.payload };
    case HOSPITAL:
      return { ...state, hospital: action.payload };
    case PARENTESCO:
      return { ...state, parentesco: action.payload };
    case PROFESIONAL_SALUD:
      return { ...state, profesionalSalud: action.payload };
    case SEVERIDAD:
      return { ...state, severidad: action.payload };
    case TIPO_SANGRE:
      return { ...state, tipoSangre: action.payload };
    case COAGULOPATIA:
      return { ...state, coagulopatia: action.payload };
    case UMF:
      return { ...state, umf: action.payload };
    case UPLOADED_DIRECTORIES:
      return { ...state, uploadedDirectories: action.payload };
    case REQ_DIRECTORIES:
      return { ...state, reqDirectories: action.payload };
    case CURRENT_CATALOG:
      return { ...state, currentCatalog: action.payload };
    case CATALOG_CREATED:
      return { ...state, createdCatalog: action.payload };
    case CATALOG_EDITED:
      return { ...state, editedCatalog: action.payload };
    case SHOW_TEXT:
      return { ...state, showText: action.payload };
    case RESPONSE_ERROR:
      return { ...state, responseError: action.payload };
    default:
      return { ...state };
  }
}
