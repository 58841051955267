import "./assets/styles/App.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { NavProvider } from "./context/NavContext";
import NotFound from "./continers/NotFound";
import Profile from "./continers/profile/Profile";
import MedicineList from "./continers/medication/MedicationList";
import MedicineForm from "./continers/medication/MedicationForm";
import BleedingList from "./continers/bleeding/BleedingList";
import BleedingForm from "./continers/bleeding/BleedingForm";
import Login from "./continers/authentication/Login";
import Activation from "./continers/authentication/Activation";
import { MENU_OPTION } from "../src/util/directory";

export default function App() {
  return (
    <AuthProvider>
      <NavProvider>
        <BrowserRouter>
          <Routes>
            <Route path={MENU_OPTION.LOGIN} element={<Login />} />
            <Route path={MENU_OPTION.ACTIVATION} element={<Activation />} />
            <Route path={MENU_OPTION.BLEEDING} element={<BleedingList />} />
            <Route
              path={MENU_OPTION.BLEEDING_ID + ":id"}
              element={<BleedingForm />}
            />
            <Route path={MENU_OPTION.MEDICATION} element={<MedicineList />} />
            <Route
              path={MENU_OPTION.MEDICATION_ID + ":id"}
              element={<MedicineForm />}
            />

            <Route path={MENU_OPTION.PROFILE} element={<Profile />} />
            <Route index element={<BleedingList />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </NavProvider>
    </AuthProvider>
  );
}
