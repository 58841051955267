import axiosService from "../../util/axiosService";
import { ACCION, ROLE_TYPE } from "../../util/directory.js";
import {
  USER_EDITED,
  USER_CREATED,
  USERS_SEARCHED,
  USER_CURRENT,
  SHOW_TEXT,
  SEARCH_REALIZED,
  RESPONSE_ERROR,
} from "../_types/usersTypes";
import { PAGINATION } from "../_types/paginationType";
import { signUp, signInFinalize } from "./authAction";
import { catchErrorNetwork } from "../../util/stringer";

let { url_get_user, url_get_user_searched, url_post_user } =
  require("../../configs/Ambiente.json")["urls"];

export const setShowText = (text) => async (dispatch) => {
  dispatch({ type: SHOW_TEXT, payload: text });
  dispatch({ type: RESPONSE_ERROR, payload: false });
};

export const setUserWasCreated = (isValid) => async (dispatch) => {
  dispatch({
    type: USER_CREATED,
    payload: isValid,
  });
};

export const setUserWasEdited = (isValid) => async (dispatch) => {
  dispatch({
    type: USER_EDITED,
    payload: isValid,
  });
};

export const checkRolUser = (username) => async (dispatch) => {
  let body = {
    typeAccion: ACCION.OBTENER_ROL,
    username,
    is: [ROLE_TYPE.PACIENTE.ID],
  };

  axiosService({
    type: "post",
    url: url_get_user,
    body,
    success: async (res) => {
      let { isSuccess, error } = res.data;
      console.log("check", res);
      if (isSuccess) {
        if (!error) {
          dispatch({ type: USER_CURRENT, payload: res.data });
          dispatch(signInFinalize(res.data, ""));
          return;
        }
      }

      dispatch({ type: USER_CURRENT, payload: null });
      dispatch(signInFinalize(null, error));
      return;
    },
    fail: (error) => {
      dispatch({ type: USER_CURRENT, payload: null });
      dispatch(signInFinalize(null, error));
      return;
    },
  });
};

export const getUser = (id) => async (dispatch) => {
  let body = { typeAccion: ACCION.OBTENER_ID, id };
  axiosService({
    type: "post",
    url: url_get_user,
    body,
    success: (res) => {
      let { isSuccess, error, results } = res.data;
      if (isSuccess) {
        if (!error) {
          dispatch({ type: USER_CURRENT, payload: results[0] });
          dispatch({ type: SHOW_TEXT, payload: "" });
          dispatch({ type: RESPONSE_ERROR, payload: false });
        } else {
          dispatch({ type: USER_CURRENT, payload: [] });
          dispatch({ type: SHOW_TEXT, payload: error });
          dispatch({ type: RESPONSE_ERROR, payload: true });
        }
      } else {
        dispatch({ type: USER_CURRENT, payload: [] });
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: USER_CURRENT, payload: [] });
      dispatch({ type: SHOW_TEXT, payload: catchErrorNetwork(null, err) });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const setSerchRealized = (value) => async (dispatch) => {
  dispatch({ type: SEARCH_REALIZED, payload: value });
};

export const getUserSearched = (chain, page, limit) => async (dispatch) => {
  chain = !chain ? "&&" : chain;
  let body = { typeAccion: ACCION.BUSCAR, data: chain, page, limit };
  axiosService({
    type: "post",
    url: url_get_user_searched,
    body,
    success: (res) => {
      let { isSuccess, error, results, total } = res.data;
      if (isSuccess) {
        if (!error) {
          dispatch({ type: USERS_SEARCHED, payload: results });
          dispatch({ type: SEARCH_REALIZED, payload: true });
          dispatch({ type: SHOW_TEXT, payload: "" });
          dispatch({ type: RESPONSE_ERROR, payload: false });
        } else {
          dispatch({ type: USERS_SEARCHED, payload: [] });
          dispatch({ type: SEARCH_REALIZED, payload: true });
          dispatch({ type: SHOW_TEXT, payload: error });
          dispatch({ type: RESPONSE_ERROR, payload: true });
        }
        dispatch({
          type: PAGINATION,
          payload: { page: page, total: total },
        });
      } else {
        dispatch({ type: PAGINATION, payload: { page: 1, total: 0 } });
        dispatch({ type: USERS_SEARCHED, payload: [] });
        dispatch({ type: SEARCH_REALIZED, payload: true });
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SEARCH_REALIZED, payload: true });
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const createUser = (data) => async (dispatch) => {
  let body = { typeAccion: ACCION.CREAR, ...data };
  axiosService({
    type: "post",
    url: url_post_user,
    body,
    success: (res) => {
      let { isSuccess, error, results, statusCode } = res.data;
      if (isSuccess && !error) {
        dispatch(
          signUp(
            `${data.nombre} ${data.ap_paterno} ${data.ap_materno}`,
            data.email,
            data.telefono,
            results
          )
        );
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const createUserFinished = (isSuccess, results, error) => (dispatch) => {
  if (isSuccess) {
    dispatch({ type: USER_CREATED, payload: true });
    dispatch({ type: USER_CURRENT, payload: results[0] });
    dispatch({ type: RESPONSE_ERROR, payload: false });
    dispatch({
      type: SHOW_TEXT,
      payload: `El usuario ${results[0].nombre} ${results[0].ap_paterno} ${results[0].ap_materno} fue creado con el ID ${results[0].id_usuario} y ${error}`,
    });
  } else {
    dispatch(deleteUserInCreation(results[0].id_usuario, error));
  }
};

export const editUser = (data) => async (dispatch) => {
  let body = { typeAccion: ACCION.MODIFICAR, ...data };
  axiosService({
    type: "post",
    url: url_post_user,
    body,
    success: (res) => {
      let { isSuccess, error, results } = res.data;
      if (isSuccess && !error) {
        dispatch({ type: USER_EDITED, payload: true });
        dispatch({ type: USER_CURRENT, payload: results[0] });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({
          type: SHOW_TEXT,
          payload: `El usuario ${results[0].nombre} ${results[0].ap_paterno} ${results[0].ap_materno} ha sido actualizado`,
        });
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const deleteUserInCreation =
  (id_usuario, msgSuccess) => async (dispatch) => {
    axiosService({
      type: "post",
      url: url_post_user,
      body: { typeAccion: ACCION.BORRAR, id_usuario: id_usuario },
      success: (res) => {
        let { isSuccess, error, results, statusCode } = res.data;
        if (isSuccess && !error) {
          if (msgSuccess) {
            dispatch({ type: SHOW_TEXT, payload: msgSuccess });
            dispatch({ type: RESPONSE_ERROR, payload: true });
          } else {
            dispatch({ type: SHOW_TEXT, payload: results });
            dispatch({ type: RESPONSE_ERROR, payload: false });
          }
        } else {
          dispatch({
            type: SHOW_TEXT,
            payload: catchErrorNetwork(statusCode, JSON.stringify(error)),
          });
          dispatch({ type: RESPONSE_ERROR, payload: true });
        }
      },
      fail: (err) => {
        dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      },
    });
  };
