import axiosService from "../../util/axiosService";
import { CATALOG_OBJ } from "../../util/prototypes";
import { ACCION, CATALOGS_LOADED } from "../../util/directory";
import {
  CONTACT_TYPES,
  REGIONES_GENERALES,
  REGIONES_ESPECIFICAS,
  NATURALEZA_SANGRADO,
  TRATAMIENTO,
  ESQUEMA,
  ARTROPATIA,
  ASEGURADORA,
  DIAGNOSTICOS,
  DIRECCION,
  ENTIDAD_FEDERATIVA,
  ESCOLARIDAD,
  ESTATUS_SS,
  INSTITUCIONES,
  HOSPITAL,
  PARENTESCO,
  PROFESIONAL_SALUD,
  SEVERIDAD,
  TIPO_SANGRE,
  COAGULOPATIA,
  UMF,
  UPLOADED_DIRECTORIES,
  REQ_DIRECTORIES,
  CURRENT_CATALOG,
  CATALOG_CREATED,
  CATALOG_EDITED,
  SHOW_TEXT,
  RESPONSE_ERROR,
} from "../_types/catalogsType";

let {
  url_x_contact_types,
  url_x_region_general,
  url_x_region_especifica,
  url_x_naturaleza_sangrado,
  url_x_tratamiento,
  url_x_esquema,
  url_x_artropatia,
  url_x_aseguradora,
  url_x_diagnostico,
  url_x_direccion,
  url_x_entidad_federativa,
  url_x_escolaridad,
  url_x_estatus_ss,
  url_x_institucion,
  url_x_hospital,
  url_x_parentesco,
  url_x_profesional_salud,
  url_x_severidad,
  url_x_tipo_sangre,
  url_x_coagulopatia,
  url_x_umf,
} = require("../../configs/Ambiente.json")["urls"];

var servicesRequested = [];
var requestedSuccess = 0;
var requestedFail = 0;

export const loadDirectories = () => async (dispatch) => {
  await dispatch(getContactTypes());
  await dispatch(getRegionGeneral());
  await dispatch(getRegionEspecifica());
  await dispatch(getNaturalezaSangrado());
  await dispatch(getTratamiento());
  await dispatch(getEsquema());
  await dispatch(getArtopatia());
  await dispatch(getAseguradora());
  await dispatch(getDiagnosticos());
  await dispatch(getEscolaridad());
  await dispatch(getEstatusSS());
  await dispatch(getInstitucion());
  await dispatch(getHospital());
  await dispatch(getParentesco());
  await dispatch(getProfesionalSalud());
  await dispatch(getSeveridad());
  await dispatch(getTipoSangre());
  await dispatch(getCoagulopatia());
  await dispatch(getUmf());
  await dispatch(getEntidadFederativa());
};

const getCatalog = (type) => {
  switch (type) {
    case CATALOG_OBJ.CONTACT_TYPE.NAME:
      return url_x_contact_types;
    case CATALOG_OBJ.REGIONES_GENERALES.NAME:
      return url_x_region_general;
    case CATALOG_OBJ.REGIONES_ESPECIFICAS.NAME:
      return url_x_region_especifica;
    case CATALOG_OBJ.NATURALEZA_SANGRADO.NAME:
      return url_x_naturaleza_sangrado;
    case CATALOG_OBJ.TRATAMIENTO.NAME:
      return url_x_tratamiento;
    case CATALOG_OBJ.ESQUEMA.NAME:
      return url_x_esquema;
    case CATALOG_OBJ.ARTROPATIAS.NAME:
      return url_x_artropatia;
    case CATALOG_OBJ.ASEGURADORAS.NAME:
      return url_x_aseguradora;
    case CATALOG_OBJ.DIAGNOSTICO.NAME:
      return url_x_diagnostico;
    case CATALOG_OBJ.ENTIDADES_FEDERATIVAS.NAME:
      return url_x_entidad_federativa;
    case CATALOG_OBJ.ESCOLARIDAD.NAME:
      return url_x_escolaridad;
    case CATALOG_OBJ.ESTATUS_SS.NAME:
      return url_x_estatus_ss;
    case CATALOG_OBJ.INSTITUCIONES.NAME:
      return url_x_institucion;
    case CATALOG_OBJ.HOSPITALES.NAME:
      return url_x_hospital;
    case CATALOG_OBJ.PARENTESCO.NAME:
      return url_x_parentesco;
    case CATALOG_OBJ.PROFESIONALES_SALUD.NAME:
      return url_x_profesional_salud;
    case CATALOG_OBJ.SEVERIDAD.NAME:
      return url_x_severidad;
    case CATALOG_OBJ.TIPO_SANGRE.NAME:
      return url_x_tipo_sangre;
    case CATALOG_OBJ.COAGULOPATIAS.NAME:
      return url_x_coagulopatia;
    case CATALOG_OBJ.UMF.NAME:
      return url_x_umf;
    default:
      return "empty";
  }
};

export const setShowText = (text) => async (dispatch) => {
  dispatch({ type: SHOW_TEXT, payload: text });
};

export const setCatalogWasCreated = (isValid) => async (dispatch) => {
  dispatch({
    type: CATALOG_CREATED,
    payload: isValid,
  });
};

export const setCatalogWasEdited = (isValid) => async (dispatch) => {
  dispatch({
    type: CATALOG_EDITED,
    payload: isValid,
  });
};

export const createCatalog = (data, catalog) => async (dispatch) => {
  let body = { typeAccion: ACCION.CREAR, ...data };
  axiosService({
    type: "post",
    url: getCatalog(catalog),
    body,
    success: (res) => {
      let { isSuccess, error, results } = res.data;
      if (isSuccess && !error) {
        dispatch({ type: catalog, payload: results });
        dispatch({ type: CATALOG_CREATED, payload: true });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({
          type: SHOW_TEXT,
          payload: "Se guardo éxitosamente",
        });
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const editCatalog = (id, data, catalog) => async (dispatch) => {
  let body = { id, typeAccion: ACCION.MODIFICAR, ...data };
  axiosService({
    type: "post",
    url: getCatalog(catalog),
    body,
    success: (res) => {
      let { isSuccess, error, results } = res.data;
      if (isSuccess && !error) {
        dispatch({ type: catalog, payload: results });
        dispatch({ type: CATALOG_EDITED, payload: true });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({
          type: SHOW_TEXT,
          payload: "Se edito éxitosamente",
        });
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const setCurrentCatalog = (view) => async (dispatch) => {
  await dispatch({ type: CURRENT_CATALOG, payload: view });
};

export const setReqDirectories = (isReq) => async (dispatch) => {
  await dispatch({ type: REQ_DIRECTORIES, payload: isReq });
};

export const setDirectoryLoaded =
  (name, finishedSuccess) => async (dispatch) => {
    if (finishedSuccess) {
      requestedSuccess += 1;
      //console.log("solicitados ", servicesRequested);
    } else {
      requestedFail += 1;
      /*console.log(
        "exitosos ",
        requestedSuccess,
        " Con error ",
        requestedFail,
        "falló > ",
        name
      );*/
    }

    if (servicesRequested.length === 0) {
      await dispatch({
        type: UPLOADED_DIRECTORIES,
        payload: CATALOGS_LOADED.CHARGING,
      });
    } else if (requestedSuccess === servicesRequested.length) {
      await dispatch({
        type: UPLOADED_DIRECTORIES,
        payload: CATALOGS_LOADED.CHARGE_SUCCESS,
      });
    } else if (
      requestedSuccess + requestedFail === servicesRequested.length &&
      requestedFail > 0
    ) {
      await dispatch({
        type: UPLOADED_DIRECTORIES,
        payload: CATALOGS_LOADED.CHARGE_FAIL,
      });
    }
  };

export const setRequestCatalog = (nameCat, deletCat = false) => {
  if (!deletCat) {
    servicesRequested.push(nameCat);
  } else {
    servicesRequested = servicesRequested.filter((cat) => cat !== nameCat);
  }
};

export const reloadDirectories = () => async (dispatch) => {
  servicesRequested = [];
  requestedSuccess = 0;
  requestedFail = 0;

  await dispatch({
    type: UPLOADED_DIRECTORIES,
    payload: CATALOGS_LOADED.CHARGING,
  });

  await dispatch(loadDirectories());
};

export const getContactTypes = () => async (dispatch) => {
  if (!servicesRequested.includes(CONTACT_TYPES)) {
    setRequestCatalog(CONTACT_TYPES);
    axiosService({
      type: "post",
      url: url_x_contact_types,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: CONTACT_TYPES, payload: results });
          dispatch(setDirectoryLoaded(CONTACT_TYPES, true));
        } else {
          dispatch({ type: CONTACT_TYPES, payload: [] });
          dispatch(setDirectoryLoaded(CONTACT_TYPES, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(CONTACT_TYPES, false));
        console.log(err);
      },
    });
  }
};

export const getRegionGeneral = () => async (dispatch) => {
  if (!servicesRequested.includes(REGIONES_GENERALES)) {
    setRequestCatalog(REGIONES_GENERALES);
    axiosService({
      type: "post",
      url: url_x_region_general,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: REGIONES_GENERALES, payload: results });
          dispatch(setDirectoryLoaded(REGIONES_GENERALES, true));
        } else {
          dispatch({ type: REGIONES_GENERALES, payload: [] });
          dispatch(setDirectoryLoaded(REGIONES_GENERALES, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(REGIONES_GENERALES, false));
        console.log(err);
      },
    });
  }
};

export const getRegionEspecifica = () => async (dispatch) => {
  if (!servicesRequested.includes(REGIONES_ESPECIFICAS)) {
    setRequestCatalog(REGIONES_ESPECIFICAS);
    axiosService({
      type: "post",
      url: url_x_region_especifica,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: REGIONES_ESPECIFICAS, payload: results });
          dispatch(setDirectoryLoaded(REGIONES_ESPECIFICAS, true));
        } else {
          dispatch({ type: REGIONES_ESPECIFICAS, payload: [] });
          dispatch(setDirectoryLoaded(REGIONES_ESPECIFICAS, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(REGIONES_ESPECIFICAS, false));
        console.log(err);
      },
    });
  }
};

export const getNaturalezaSangrado = () => async (dispatch) => {
  if (!servicesRequested.includes(NATURALEZA_SANGRADO)) {
    setRequestCatalog(NATURALEZA_SANGRADO);
    axiosService({
      type: "post",
      url: url_x_naturaleza_sangrado,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: NATURALEZA_SANGRADO, payload: results });
          dispatch(setDirectoryLoaded(NATURALEZA_SANGRADO, true));
        } else {
          dispatch({ type: NATURALEZA_SANGRADO, payload: [] });
          dispatch(setDirectoryLoaded(NATURALEZA_SANGRADO, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(NATURALEZA_SANGRADO, false));
        console.log(err);
      },
    });
  }
};

export const getTratamiento = () => async (dispatch) => {
  if (!servicesRequested.includes(TRATAMIENTO)) {
    setRequestCatalog(TRATAMIENTO);
    axiosService({
      type: "post",
      url: url_x_tratamiento,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: TRATAMIENTO, payload: results });
          dispatch(setDirectoryLoaded(TRATAMIENTO, true));
        } else {
          dispatch({ type: TRATAMIENTO, payload: [] });
          dispatch(setDirectoryLoaded(TRATAMIENTO, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(TRATAMIENTO, false));
        console.log(err);
      },
    });
  }
};

export const getEsquema = () => async (dispatch) => {
  if (!servicesRequested.includes(ESQUEMA)) {
    setRequestCatalog(ESQUEMA);
    axiosService({
      type: "post",
      url: url_x_esquema,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ESQUEMA, payload: results });
          dispatch(setDirectoryLoaded(ESQUEMA, true));
        } else {
          dispatch({ type: ESQUEMA, payload: [] });
          dispatch(setDirectoryLoaded(ESQUEMA, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(ESQUEMA, false));
        console.log(err);
      },
    });
  }
};

export const getArtopatia = () => async (dispatch) => {
  if (!servicesRequested.includes(ARTROPATIA)) {
    setRequestCatalog(ARTROPATIA);
    axiosService({
      type: "post",
      url: url_x_artropatia,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ARTROPATIA, payload: results });
          dispatch(setDirectoryLoaded(ARTROPATIA, true));
        } else {
          dispatch({ type: ARTROPATIA, payload: [] });
          dispatch(setDirectoryLoaded(ARTROPATIA, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(ARTROPATIA, false));
        console.log(err);
      },
    });
  }
};

export const getAseguradora = () => async (dispatch) => {
  if (!servicesRequested.includes(ASEGURADORA)) {
    setRequestCatalog(ASEGURADORA);
    axiosService({
      type: "post",
      url: url_x_aseguradora,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ASEGURADORA, payload: results });
          dispatch(setDirectoryLoaded(ASEGURADORA, true));
        } else {
          dispatch({ type: ASEGURADORA, payload: [] });
          dispatch(setDirectoryLoaded(ASEGURADORA, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(ASEGURADORA, false));
      },
    });
  }
};

export const getDiagnosticos = () => async (dispatch) => {
  if (!servicesRequested.includes(DIAGNOSTICOS)) {
    setRequestCatalog(DIAGNOSTICOS);
    axiosService({
      type: "post",
      url: url_x_diagnostico,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: DIAGNOSTICOS, payload: results });
          dispatch(setDirectoryLoaded(DIAGNOSTICOS, true));
        } else {
          dispatch({ type: DIAGNOSTICOS, payload: [] });
          dispatch(setDirectoryLoaded(DIAGNOSTICOS, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(DIAGNOSTICOS, false));
      },
    });
  }
};

export const getDireccion = (cp) => async (dispatch) => {
  if (!servicesRequested.includes(DIRECCION)) {
    setRequestCatalog(DIRECCION);
    axiosService({
      type: "post",
      url: url_x_direccion,
      body: { cp },
      success: (res) => {
        let { isSuccess, results, error } = res.data;
        if (isSuccess && results.length !== 0) {
          dispatch({
            type: DIRECCION,
            payload: {
              cp: results[0].cp,
              idEstado: results[0].idEstado,
              estado: results[0].estado,
              ciudad: results[0].ciudad,
              idMunicipio: results[0].idMunicipio,
              municipio: results[0].municipio,
              asentamientos: results.map((item) => {
                return { name: item.asentamiento };
              }),
            },
          });
          dispatch(setDirectoryLoaded(DIRECCION, true));
        } else {
          console.log(error);
          dispatch({ type: DIRECCION, payload: [] });
          dispatch(setDirectoryLoaded(DIRECCION, false));
        }
        setRequestCatalog(DIRECCION, true);
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(DIRECCION, false));
        setRequestCatalog(DIRECCION, true);
      },
    });
  }
};

export const getEntidadFederativa = () => async (dispatch) => {
  if (!servicesRequested.includes(ENTIDAD_FEDERATIVA)) {
    setRequestCatalog(ENTIDAD_FEDERATIVA);
    axiosService({
      type: "post",
      url: url_x_entidad_federativa,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ENTIDAD_FEDERATIVA, payload: results });
          dispatch(setDirectoryLoaded(ENTIDAD_FEDERATIVA, true));
        } else {
          dispatch({ type: ENTIDAD_FEDERATIVA, payload: [] });
          dispatch(setDirectoryLoaded(ENTIDAD_FEDERATIVA, false));
        }
      },
      fail: (err) => {
        dispatch(setDirectoryLoaded(ENTIDAD_FEDERATIVA, false));
        console.log(err);
      },
    });
  }
};

export const getEscolaridad = () => async (dispatch) => {
  if (!servicesRequested.includes(ESCOLARIDAD)) {
    setRequestCatalog(ESCOLARIDAD);
    axiosService({
      type: "post",
      url: url_x_escolaridad,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ESCOLARIDAD, payload: results });
          dispatch(setDirectoryLoaded(ESCOLARIDAD, true));
        } else {
          dispatch({ type: ESCOLARIDAD, payload: [] });
          dispatch(setDirectoryLoaded(ESCOLARIDAD, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(ESCOLARIDAD, false));
      },
    });
  }
};

export const getEstatusSS = () => async (dispatch) => {
  if (!servicesRequested.includes(ESTATUS_SS)) {
    setRequestCatalog(ESTATUS_SS);
    axiosService({
      type: "post",
      url: url_x_estatus_ss,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: ESTATUS_SS, payload: results });
          dispatch(setDirectoryLoaded(ESTATUS_SS, true));
        } else {
          dispatch({ type: ESTATUS_SS, payload: [] });
          dispatch(setDirectoryLoaded(ESTATUS_SS, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(ESTATUS_SS, false));
      },
    });
  }
};

export const getInstitucion = () => async (dispatch) => {
  if (!servicesRequested.includes(INSTITUCIONES)) {
    setRequestCatalog(INSTITUCIONES);
    axiosService({
      type: "post",
      url: url_x_institucion,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: INSTITUCIONES, payload: results });
          dispatch(setDirectoryLoaded(INSTITUCIONES, true));
        } else {
          dispatch({ type: INSTITUCIONES, payload: [] });
          dispatch(setDirectoryLoaded(INSTITUCIONES, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(INSTITUCIONES, false));
      },
    });
  }
};

export const getHospital = () => async (dispatch) => {
  if (!servicesRequested.includes(HOSPITAL)) {
    setRequestCatalog(HOSPITAL);
    axiosService({
      type: "post",
      url: url_x_hospital,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: HOSPITAL, payload: results });
          dispatch(setDirectoryLoaded(HOSPITAL, true));
        } else {
          dispatch({ type: HOSPITAL, payload: [] });
          dispatch(setDirectoryLoaded(HOSPITAL, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(HOSPITAL, false));
      },
    });
  }
};

export const getParentesco = () => async (dispatch) => {
  if (!servicesRequested.includes(PARENTESCO)) {
    setRequestCatalog(PARENTESCO);
    axiosService({
      type: "post",
      url: url_x_parentesco,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: PARENTESCO, payload: results });
          dispatch(setDirectoryLoaded(PARENTESCO, true));
        } else {
          dispatch({ type: PARENTESCO, payload: [] });
          dispatch(setDirectoryLoaded(PARENTESCO, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(PARENTESCO, false));
      },
    });
  }
};

export const getProfesionalSalud = () => async (dispatch) => {
  if (!servicesRequested.includes(PROFESIONAL_SALUD)) {
    setRequestCatalog(PROFESIONAL_SALUD);
    axiosService({
      type: "post",
      url: url_x_profesional_salud,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: PROFESIONAL_SALUD, payload: results });
          dispatch(setDirectoryLoaded(PROFESIONAL_SALUD, true));
        } else {
          dispatch({ type: PROFESIONAL_SALUD, payload: [] });
          dispatch(setDirectoryLoaded(PROFESIONAL_SALUD, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(PROFESIONAL_SALUD, false));
      },
    });
  }
};

export const getSeveridad = () => async (dispatch) => {
  if (!servicesRequested.includes(SEVERIDAD)) {
    setRequestCatalog(SEVERIDAD);
    axiosService({
      type: "post",
      url: url_x_severidad,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: SEVERIDAD, payload: results });
          dispatch(setDirectoryLoaded(SEVERIDAD, true));
        } else {
          dispatch({ type: SEVERIDAD, payload: [] });
          dispatch(setDirectoryLoaded(SEVERIDAD, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(SEVERIDAD, false));
      },
    });
  }
};

export const getTipoSangre = () => async (dispatch) => {
  if (!servicesRequested.includes(TIPO_SANGRE)) {
    setRequestCatalog(TIPO_SANGRE);
    axiosService({
      type: "post",
      url: url_x_tipo_sangre,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: TIPO_SANGRE, payload: results });
          dispatch(setDirectoryLoaded(TIPO_SANGRE, true));
        } else {
          dispatch({ type: TIPO_SANGRE, payload: [] });
          dispatch(setDirectoryLoaded(TIPO_SANGRE, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(TIPO_SANGRE, false));
      },
    });
  }
};

export const getCoagulopatia = () => async (dispatch) => {
  if (!servicesRequested.includes(COAGULOPATIA)) {
    setRequestCatalog(COAGULOPATIA);
    axiosService({
      type: "post",
      url: url_x_coagulopatia,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: COAGULOPATIA, payload: results });
          dispatch(setDirectoryLoaded(COAGULOPATIA, true));
        } else {
          dispatch({ type: COAGULOPATIA, payload: [] });
          dispatch(setDirectoryLoaded(COAGULOPATIA, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(COAGULOPATIA, false));
      },
    });
  }
};

export const getUmf = () => async (dispatch) => {
  if (!servicesRequested.includes(UMF)) {
    setRequestCatalog(UMF);
    axiosService({
      type: "post",
      url: url_x_umf,
      success: (res) => {
        let { isSuccess, results } = res.data;
        if (isSuccess) {
          dispatch({ type: UMF, payload: results });
          dispatch(setDirectoryLoaded(UMF, true));
        } else {
          dispatch({ type: UMF, payload: [] });
          dispatch(setDirectoryLoaded(UMF, false));
        }
      },
      fail: (err) => {
        console.log(err);
        dispatch(setDirectoryLoaded(UMF, false));
      },
    });
  }
};
