import { React, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import icSync from "../../assets/static/ic_download_catalog_black.svg";
import LayoutMain from "../LayoutMain";
import Button from "../../components/Button";
import LoaderPage from "../../components/LoaderPage";
import Modal from "../../components/Modal";
import InputMultipleSelection from "../../components/InputMultipleSelection";
import icClose from "../../assets/static/ic_close_white.svg";
import {
  MENU_OPTION,
  MED_FIELD,
  ROLE_TYPE,
  CATALOGS_LOADED,
} from "../../util/directory";
import {
  validateIsOnlyNumber,
  formatDate,
  getSpecificDate,
} from "../../util/stringer";
import AuthContext from "../../context/AuthContext";
import NavContext from "../../context/NavContext";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDirectories,
  reloadDirectories,
} from "../../redux/_actions/catalogsAction";
import {
  getMedication,
  setMedicationWasCreated,
  setMedicationWasEdited,
  createMedication,
  editMedication,
  setShowText,
} from "../../redux/_actions/medicationAction.js";
import {
  getBleedingSearched,
  setSerchRealized,
} from "../../redux/_actions/bleedingAction";

export default function MedicationForm(props) {
  let emptyRequest = {
    id_usuario: 0,
    id_medicacion_persona_clinica: 0,
    fecha: "",
    hora: "",
    motivo: "",
    id_tratamiento: 0,
    id_esquema: 0,
    viales: "",
    unidades: "",
    lote: [],
    caducidad: "",
    id_sangrado_persona_clinica: 0,
    registro: "",
  };

  let emptyErrorRequest = {
    id_usuario: "",
    id_medicacion_persona_clinica: "",
    fecha: "",
    hora: "",
    motivo: "",
    id_tratamiento: "",
    id_esquema: "",
    viales: "",
    unidades: "",
    lote: "",
    caducidad: "",
    id_sangrado_persona_clinica: "",
    registro: "",
  };

  let navigate = useNavigate();
  const [request, setRequest] = useState(emptyRequest);
  const [errorRequest, setErrorRequest] = useState(emptyErrorRequest);
  const dispatch = useDispatch();
  const [isCreation, setIsCreation] = useState(null);
  const [param, setParam] = useState("");
  const { isLogged, user } = useContext(AuthContext);
  const { setView } = useContext(NavContext);
  const [renderPage, setRenderPage] = useState(false);
  const [mandatoryFieldsAreOk, setMandatoryFieldsAreOk] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [disableAddLote, setDisableAddLote] = useState(false);
  const [currentLote, setCurrentLote] = useState("");
  const [searching, setSearching] = useState("");
  const { page, total, limit } = useSelector((state) => state.pagination);
  //  const [bleedingRelated, setBleedingRelated] = useState(0);
  const [showBleedingList, setShowBleedingList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [dinamicList, setDinamicList] = useState([]);

  let {
    regionesGenerales,
    regionesEspecificas,
    naturalezaSangrado,
    tratamiento,
    esquema,
    aseguradora,
    instituciones,
    hospital,
    umf,
    entidadFederativa,
    uploadedDirectories,
    reqDirectories,
    //currentCatalog,
  } = useSelector((state) => state.catalogs);

  let {
    medicationCurrent,
    medicationCreated,
    medicationEdited,
    showText,
    responseError,
  } = useSelector((state) => state.medication);

  let { bleedingSearched, bleedingSearchRealized } = useSelector(
    (state) => state.bleeding
  );

  let { id } = useParams();

  /**
   *
   * Main Functions
   *
   **/
  const setErrorValue = (name, value) => {
    setErrorRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setValueToRequest = (name, value) => {
    setRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeBleedingForm = (name, value) => {
    let idBlood = value[0]?.id || 0;
    console.log(name, idBlood);
    setValueToRequest(name, idBlood);
    validateRequestField(name, idBlood);
  };

  const onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValueToRequest(name, value);
    validateRequestField(name, value);
  };

  const onChangeLote = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentLote(value);
    validateRequestField(name, value);
  };

  const addLote = () => {
    if (currentLote) {
      let lotes = request?.lote || [];
      lotes.push(currentLote);
      setValueToRequest(MED_FIELD.LOTE, lotes);
      setCurrentLote("");
    }
  };

  const subtractLote = (pos) => {
    let lotes = request?.lote || [];
    lotes.splice(pos, 1);
    setValueToRequest(MED_FIELD.LOTE, lotes);
    setCurrentLote("");
  };

  const handleEnter = (e) => {
    if (e.charCode === 13) {
      if (!errorRequest.lote) {
        addLote();
      }
    }
  };

  const onSave = () => {
    request.registro = getSpecificDate(); //new Date()

    console.log("on save", request);

    if (isCreation) {
      dispatch(createMedication(request));
    } else {
      dispatch(editMedication(request));
    }

    setSavingInProgress(true);
  };

  useEffect(() => {
    if (user?.signInUserSession?.idToken?.jwtToken) {
      if (!uploadedDirectories && reqDirectories) {
        dispatch(loadDirectories());
      }
    }
  }, [uploadedDirectories, reqDirectories]);

  useEffect(() => {
    if (medicationCreated) {
      setSavingInProgress(false);
      dispatch(setMedicationWasCreated(false));
    }
  }, [medicationCreated]);

  useEffect(() => {
    if (medicationEdited) {
      setSavingInProgress(false);
      dispatch(setMedicationWasEdited(false));
    }
  }, [medicationEdited]);

  const modalManage = (isOpen) => {
    setSavingInProgress(false);
    dispatch(setShowText(!isOpen ? "" : isOpen));
    if (!responseError) {
      if (isCreation) {
        backToList();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [searching]);

  const handleSearching = (text) => {
    setSearching(text);
  };

  const getData = async () => {
    setLoadingData(true);
    dispatch(
      getBleedingSearched(
        searching,
        page,
        limit,
        user.attributes ? user.attributes.email : ""
      )
    );
  };

  useEffect(() => {}, [user]);

  useEffect(() => {
    setDinamicList(bleedingSearched);
  }, [bleedingSearched]);

  useEffect(() => {
    if (bleedingSearchRealized) {
      setLoadingData(false);
      dispatch(setSerchRealized(false));
    }
  }, [bleedingSearchRealized]);

  const buildTextBleeding = (motivo, fecha, hora) => {
    return motivo + " - " + formatDate(fecha) + " " + hora;
  };

  useEffect(() => {
    let list = [];
    if (dinamicList && dinamicList.length >= 0) {
      list = dinamicList.map((bleeding) => {
        bleeding.selected =
          bleeding.id_sangrado_persona_clinica ==
          request?.id_sangrado_persona_clinica;
        bleeding.motivo = buildTextBleeding(
          bleeding.motivo,
          bleeding.fecha,
          bleeding.hora
        );
        return bleeding;
      });
    }

    setShowBleedingList(list);
  }, [dinamicList]);

  /**
   *
   * Validations to Request
   *
   **/

  const validateRequestField = (name, value) => {
    switch (name) {
      case MED_FIELD.MOTIVO:
        if (!value) {
          setErrorValue(name, "El campo no puede ir vacio");
        } else {
          setErrorValue(name, "");
        }
        break;
      case MED_FIELD.FECHA:
        if (
          !value ||
          Object.prototype.toString.call(value) === "[object Date]"
        ) {
          setErrorValue(name, "Seleccione una fecha");
        } else {
          setErrorValue(name, "");
        }
        break;
      case MED_FIELD.HORA:
        if (!value) {
          setErrorValue(name, "Seleccione una hora");
        } else {
          setErrorValue(name, "");
        }
        break;
      case MED_FIELD.LOTE:
        if (value) {
          if (!validateIsOnlyNumber(value)) {
            setErrorValue(name, "Escribe solo caracteres númericos");
            setDisableAddLote(true);
          } else {
            setErrorValue(name, "");
            setDisableAddLote(false);
          }
        } else {
          setErrorValue(name, "");
          setDisableAddLote(true);
        }
        break;
      case MED_FIELD.VIALES:
        if (value) {
          if (!validateIsOnlyNumber(value)) {
            setErrorValue(name, "Escribe solo caracteres númericos");
          } else {
            setErrorValue(name, "");
          }
        } else {
          setErrorValue(name, "");
        }
        break;
      default:
        break;
    }
  };

  /**
   *
   * Params control
   *
   **/
  useEffect(() => {
    if (id) {
      if (id !== undefined) {
        setParam(atob(id));
        //setCurrentlyInEdition(false);
      }
    }
  }, [id]);

  useEffect(() => {
    if (param) {
      if (param === "creation") {
        //console.log(1, "is a creation", request);
        setIsCreation(true);
      } else {
        //console.log(1, "is an edition", request);
        setIsCreation(false);
        setMandatoryFieldsAreOk(true);
      }
    }
  }, [param]);

  /**
   *
   * Creation Control
   *
   **/
  useEffect(() => {
    if (isCreation !== null && param && user) {
      if (isCreation) {
        setRequest((prevState) => ({
          ...prevState,
          id_usuario: user.attributes ? user.attributes.email : "",
        }));
      } else {
        setTimeout(() => {
          dispatch(getMedication(param));
        }, 1200);
      }

      getData();
    }
  }, [isCreation, param, user]);

  /**
   *
   * Edition Control
   *
   **/
  useEffect(() => {
    //Si es una edición y los datos del medicationCurrent se actualizaron
    if (isCreation !== null) {
      if (medicationCurrent) {
        if (!isCreation) {
          medicationCurrent.fecha = formatDate(medicationCurrent.fecha);
          medicationCurrent.caducidad = formatDate(medicationCurrent.caducidad);
          medicationCurrent.id_usuario = user.attributes
            ? user.attributes.email
            : "";
          setDinamicList(medicationCurrent.sangrado_persona_clinica);
          setRequest({ ...medicationCurrent });
        }
      }
    }
  }, [medicationCurrent]);

  /**
   *
   * Update mandatory fields
   *
   **/
  useEffect(() => {
    let hasError =
      errorRequest.fecha ||
      errorRequest.hora ||
      errorRequest.motivo ||
      errorRequest.id_tratamiento ||
      errorRequest.id_esquema ||
      errorRequest.viales ||
      errorRequest.unidades ||
      errorRequest.lote ||
      errorRequest.caducidad;

    if (!hasError) {
      let dataFields = request.fecha && request.hora && request.motivo;

      if (dataFields) {
        setMandatoryFieldsAreOk(true);
      } else {
        setMandatoryFieldsAreOk(false);
      }
    } else {
      setMandatoryFieldsAreOk(false);
    }
  }, [errorRequest]);

  useEffect(() => {
    let dataFields = request.fecha && request.hora && request.motivo;

    if (dataFields) {
      setMandatoryFieldsAreOk(true);
    } else {
      setMandatoryFieldsAreOk(false);
    }
  }, [request]);

  /**
   *
   * Start Navigation and loader
   *
   **/
  const reloadAllDirectories = () => {
    dispatch(reloadDirectories());
  };

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  const backToList = () => {
    setNav(MENU_OPTION.MEDICATION);
  };

  useEffect(() => {
    if (isLogged) {
      setRenderPage(true);
    }
  }, [isLogged]);

  useEffect(() => {
    if (renderPage && isLogged) {
      setView(MENU_OPTION.MEDICATION_ID + id);
      setRenderPage(true);
      setShowText("");
    }
  }, [renderPage]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setNav(MENU_OPTION.LOGIN);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <LayoutMain>
      <div className="container-body">
        <div className="container-title">
          <h3 className="title-left">
            {`${isCreation ? "Agregar medicación" : "Editar medicación"}`}
          </h3>
          <div className="btn-right">
            <Button color="accent-color" onClick={() => backToList()}>
              {"Administración de medicamento"}
            </Button>
          </div>
        </div>

        {!request.id_usuario ? (
          <LoaderPage />
        ) : (
          <>
            <div className="form form-profile">
              <div className="container-title container-title--margin-bottom">
                <h3 className="title-left">Datos de medicación</h3>
                <div className="btn-right">
                  <img
                    className={`sync-catalogs ${
                      uploadedDirectories === CATALOGS_LOADED.CHARGING
                        ? "sync-catalogs--charging"
                        : ""
                    }`}
                    src={icSync}
                    onClick={() => reloadAllDirectories()}
                  />
                </div>
              </div>

              <div className="form-data">
                <div className="form-field">
                  <label> Fecha* </label>
                  <input
                    className="dt"
                    name={MED_FIELD.FECHA}
                    value={request.fecha}
                    onChange={onChangeForm}
                    type="date"
                    required
                  />
                  <p className="form-error">{errorRequest.fecha}</p>
                </div>

                <div className="form-field">
                  <label htmlFor="fname">Hora*</label>
                  <input
                    onChange={onChangeForm}
                    className="dt"
                    name={MED_FIELD.HORA}
                    value={request.hora || ""}
                    type="time"
                    required
                  />
                  <p className="form-error">{errorRequest.hora}</p>
                </div>

                <div className="form-field">
                  <label>Motivo*</label>
                  <input
                    onChange={onChangeForm}
                    name={MED_FIELD.MOTIVO}
                    value={request.motivo || ""}
                    type="text"
                  />
                  <p className="form-error">{errorRequest.motivo}</p>
                </div>

                <div className="form-field">
                  <label> Tratamiento administrado*</label>
                  <select
                    name={MED_FIELD.ID_TRATAMIENTO}
                    onChange={onChangeForm}
                    defaultValue={request.id_tratamiento}
                  >
                    <option value="0">Seleccione el tratamiento</option>
                    {tratamiento &&
                      tratamiento.map((row, i) => {
                        return (
                          <option
                            key={i}
                            value={row.id_tratamiento}
                            selected={
                              row.id_tratamiento === request.id_tratamiento &&
                              "selected"
                            }
                          >
                            {row.tratamiento}
                          </option>
                        );
                      })}
                  </select>
                  <p className="form-error">{errorRequest.id_tratamiento}</p>
                </div>

                <div className="form-field">
                  <label> Esquema de taratamiento*</label>
                  <select
                    name={MED_FIELD.ID_ESQUEMA}
                    onChange={onChangeForm}
                    defaultValue={request.id_esquema}
                  >
                    <option value="0">
                      Seleccione el esquema de tratamiento
                    </option>
                    {esquema &&
                      esquema.map((row, i) => {
                        return (
                          <option
                            key={i}
                            value={row.id_esquema}
                            selected={
                              row.id_esquema === request.id_esquema &&
                              "selected"
                            }
                          >
                            {row.esquema}
                          </option>
                        );
                      })}
                  </select>
                  <p className="form-error">{errorRequest.id_esquema}</p>
                </div>

                <div className="form-field">
                  <label>Número de viales*</label>
                  <input
                    onChange={onChangeForm}
                    name={MED_FIELD.VIALES}
                    value={request.viales || ""}
                    type="text"
                  />
                  <p className="form-error">{errorRequest.viales}</p>
                </div>

                <div className="form-field">
                  <label> Unidades por vial*</label>
                  <input
                    onChange={onChangeForm}
                    name={MED_FIELD.UNIDADES}
                    value={request.unidades || ""}
                    type="text"
                  />
                  <p className="form-error">{errorRequest.unidades}</p>
                </div>

                <div className="form-field">
                  <label>Caducidad*</label>
                  <input
                    name={MED_FIELD.CADUCIDAD}
                    value={request.caducidad}
                    onChange={onChangeForm}
                    type="date"
                    className="dt"
                  />
                  <p className="form-error">{errorRequest.unidades}</p>
                </div>

                <div className="form-listin">
                  <label> LOTE* </label>
                  <div className="badges">
                    {request.lote.length > 0 &&
                      request.lote?.map((item, key) => {
                        return (
                          <div key={key} className="badge">
                            <input
                              className="entry"
                              value={item}
                              type="text"
                              disabled
                            />
                            <img
                              src={icClose}
                              className="circle close"
                              onClick={() => subtractLote(key)}
                            />
                          </div>
                        );
                      })}
                  </div>

                  <div className="setter">
                    <input
                      className="setter-input"
                      onChange={(e) => onChangeLote(e)}
                      value={currentLote}
                      name={MED_FIELD.LOTE}
                      onKeyPress={handleEnter}
                      type="text"
                    />

                    <Button
                      color="tertiary-color"
                      onClick={(e) => {
                        e.preventDefault();
                        addLote();
                      }}
                      disable={disableAddLote}
                    >
                      Añadir LOTE
                    </Button>
                  </div>
                  <p className="form-error">{errorRequest.lote}</p>
                </div>

                <div className="form-field">
                  <InputMultipleSelection
                    title={"Sangrado relacionado"}
                    data={showBleedingList}
                    value={"motivo"}
                    name={"id_sangrado_persona_clinica"}
                    isNotSelectable={true}
                    isPredictive={true}
                    predictiveText={searching}
                    isLoading={loadingData}
                    onChangePrediction={(text) => handleSearching(text)}
                    onChangeData={(name, value) =>
                      onChangeBleedingForm(name, value)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="submit submit-paciente">
              <Button
                color="tertiary-color"
                disable={savingInProgress || !mandatoryFieldsAreOk}
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
                inProgress={savingInProgress}
              >
                {isCreation ? "Crear aplicación" : "Actualizar aplicación"}
              </Button>
            </div>
          </>
        )}
      </div>

      <Modal
        title={showText}
        isOpen={showText.length > 0}
        handleIsOpen={(open) => modalManage(open)}
        btnPositive
        handlePositive={() => {
          modalManage(false);
        }}
      />
    </LayoutMain>
  );
}
