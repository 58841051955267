export const HEMO_FIELD = {
  ID_SANGRADO: "id_sangrado",
  FECHA: "fecha",
  HORA: "hora",
  NATURALEZA_SANGRADO: "id_naturaleza_sangrado",
  MOTIVO: "motivo",
  DETALLE: "detalle",
  REGION_GENERAL: "id_region_general",
  REGION_ESPECIFICA: "id_region_especifica",
  APLICACION: "aplicacion",
  MOTIVO_APLICACION: "motivo_aplicacion",
  MEDICACIONES: "medicaciones",
};

export const MED_FIELD = {
  ID_MEDICACION: "id_medicacion",
  FECHA: "fecha",
  HORA: "hora",
  MOTIVO: "motivo",
  ID_TRATAMIENTO: "id_tratamiento",
  ID_ESQUEMA: "id_esquema",
  VIALES: "viales",
  UNIDADES: "unidades",
  LOTE: "lote",
  CADUCIDAD: "caducidad",
  ID_SANGRADO: "id_sangrado",
};

export const MENU_OPTION = {
  MAIN: "/",
  LOGIN: "/login",
  ACTIVATION: "/activation",
  BLEEDING: "/sangrados",
  BLEEDING_ID: "/sangrados/",
  MEDICATION: "/medicamentos",
  MEDICATION_ID: "/medicamentos/",
  REPORTS: "/reportes",
  AUTHORIZATIONS: "/autorizaciones",
  PROFILE: "/perfil",
};

export const ROLE_TYPE = {
  PREREGISTRO: { ID: 1, NAME: "PREREGISTRO" },
  SUBREGISTRO: { ID: 2, NAME: "SUBREGISTRO" },
  PACIENTE: { ID: 3, NAME: "PACIENTE" },
  ASESOR: { ID: 4, NAME: "ASESOR" },
  MEDICO: { ID: 5, NAME: "MEDICO" },
  CUIDADOR: { ID: 6, NAME: "CUIDADOR" },
  CONTENT_MANAGER: { ID: 7, NAME: "CONTENT_MANAGER" },
  COORDINADOR: { ID: 8, NAME: "COORDINADOR" },
  REGIONAL: { ID: 9, NAME: "REGIONAL" },
};

export const VIEW_TYPE = {
  PREREGISTRO: { ID: 1, NAME: "PREREGISTRO" },
  SUBREGISTRO: { ID: 2, NAME: "SUBREGISTRO" },
  PACIENTE: { ID: 3, NAME: "PACIENTE" },
  USUARIO: { ID: 4, NAME: "USUARIO" },
};

export const VALIDATION_TYPE = {
  FIELD_EMPTY: "fieldEmpty",
  SELECT_ID: "selectId",
  CURP: "curp",
};

export const ACCION = {
  OBTENER_ID: "OID",
  OBTENER: "O",
  CREAR: "C",
  MODIFICAR: "M",
  BORRAR: "B",
  BUSCAR: "S",
  OBTENER_ROL: "RID",
};

export const CATALOGS_LOADED = {
  WITHOUT_CHARGE: "WITHOUT_CHARGE",
  CHARGING: "CHARGING",
  CHARGE_SUCCESS: "CHARGE_SUCCESS",
  CHARGE_FAIL: "CHARGE_FAIL",
};

export const NETWORK_ERRORS = {
  AUTH: "auth",
  EXCEPTION: "exception",
  INVALID_PASSWORD: "password",
  USER_EXIST: "exist",
  NOT_CONFIRMED: "usernotconfirmed",
  USER_NOT_CONFIRMED: "usuario no confirmado",
  IT_WAS_CONFIRMED: "current status is confirmed",
};
