import { useEffect, useState } from "react";
import iconDone from "../assets/static/ic_done.svg";
import iconAlert from "../assets/static/ic_alert.svg";
import iconError from "../assets/static/ic_error.svg";

/**
 * *Propiedades*
 * * isOpen true/false
 *
 * * hideIcon. true/false
 *    * Oculta el icono en el alert
 *
 * * iconType. done/error/alert(Default)
 *
 * * iconImg. String
 *    * URL a renderizar en el alert. Es de mayor jerarquia que las imagenes de tipó
 *
 * * iconType. String done/alert/error
 *  * done.
 *  * alert. Default
 *  * error.
 *
 * * title. String
 *
 * * description. String
 *
 * *Botones*
 *  * btnPositive. String
 *  * btnNeutro. String
 *  * btnNeutro. String
 *
 * *Funciones*
 *  * handleIsOpen(true/false).
 *      * Control del estado fuera del componente
 *  * handlePositive()
 *  * handleNeutro()
 *  * handleNegative()
 *
 */

export default function Modal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [iconType, setIconType] = useState("");

  const onClose = () => {
    props.handleIsOpen && props.handleIsOpen(false);
  };

  const onClickPositive = () => {
    onClose();
    props.handlePositive && props.handlePositive();
  };

  const onClickNeutro = () => {
    onClose();
    props.handleNeutro && props.handleNeutro();
  };

  const onClickNegative = () => {
    onClose();
    props.handleNegative && props.handleNegative();
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
    setIconType(() => {
      switch (props.iconType) {
        case "done":
          return iconDone;
          break;
        case "error":
          return iconError;
          break;
        default:
          return iconAlert;
          break;
      }
    });
  }, [props.isOpen]);

  return (
    <div className={`modal modal--${isOpen ? "open" : "close"}`}>
      <div className="modal-content card card--white">
        <div className="modal-info">
          <div className="modal-icon">
            <img
              className={`modal-image ${props.hideIcon && "modal-image--hide"}`}
              src={props.iconImg || iconType}
            />
          </div>

          <div className="modal-body">
            {props.title && <h3 className="modal-title">{props.title}</h3>}
            {props.description && (
              <h4 className="modal-description">{props.description}</h4>
            )}
            {props.children}
          </div>
          <div className="modal-actions">
            {props.btnPositive && (
              <button
                className="modal-btn modal-btn--positive"
                onClick={(e) => {
                  e.preventDefault();
                  onClickPositive();
                }}
              >
                <span>
                  {props.btnPositive == true ? "Aceptar" : props.btnPositive}
                </span>
              </button>
            )}
            {props.btnNeutro && (
              <button
                className="modal-btn modal-btn--neutro"
                onClick={(e) => {
                  e.preventDefault();
                  onClickNeutro();
                }}
              >
                <span>
                  {props.btnNeutro == true ? "Entiendo" : props.btnNeutro}
                </span>
              </button>
            )}

            {props.btnNegative && (
              <button
                className="modal-btn modal-btn--negative"
                onClick={(e) => {
                  e.preventDefault();
                  onClickNegative();
                }}
              >
                <span>
                  {props.btnNegative == true ? "Cancelar" : props.btnNegative}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
