import { React, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import icSync from "../../assets/static/ic_download_catalog_black.svg";
import LayoutMain from "../LayoutMain";
import Button from "../../components/Button";
import LoaderPage from "../../components/LoaderPage";
import Modal from "../../components/Modal";
import CheckItem from "../../components/CheckItem";

import {
  MENU_OPTION,
  HEMO_FIELD,
  ROLE_TYPE,
  CATALOGS_LOADED,
} from "../../util/directory";

import { validateIsOnlyNumber, formatDate } from "../../util/stringer";
import AuthContext from "../../context/AuthContext";
import NavContext from "../../context/NavContext";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDirectories,
  reloadDirectories,
} from "../../redux/_actions/catalogsAction";
import {
  getBleeding,
  setBleedingWasCreated,
  setBleedingWasEdited,
  createBleeding,
  editBleeding,
  setShowText,
} from "../../redux/_actions/bleedingAction";

export default function BleedingForm(props) {
  let emptyRequest = {
    id_usuario: 0,
    id_sangrado_persona_clinica: 0,
    fecha: "",
    hora: "",
    motivo: "",
    detalle: "",
    id_naturaleza_sangrado: 0,
    id_region_general: 0,
    id_region_especifica: 0,
    aplicacion: "",
    motivo_no_aplicacion: "",
    medicaciones: [],
    registro: "",
    enable: 1,
  };

  let emptyErrorRequest = {
    id_usuario: "",
    id_sangrado_persona_clinica: "",
    fecha: "",
    hora: "",
    motivo: "",
    detalle: "",
    id_naturaleza_sangrado: "",
    id_region_general: "",
    id_region_especifica: "",
    aplicacion: "",
    motivo_no_aplicacion: "",
    medicaciones: "",
    registro: "",
  };

  let navigate = useNavigate();
  const [request, setRequest] = useState(emptyRequest);
  const [errorRequest, setErrorRequest] = useState(emptyErrorRequest);
  const dispatch = useDispatch();
  const [isCreation, setIsCreation] = useState(null);
  const [param, setParam] = useState("");
  const { isLogged, user } = useContext(AuthContext);
  const { setView } = useContext(NavContext);
  const [renderPage, setRenderPage] = useState(false);
  const [mandatoryFieldsAreOk, setMandatoryFieldsAreOk] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  let {
    regionesGenerales,
    regionesEspecificas,
    naturalezaSangrado,
    tratamiento,
    esquema,
    aseguradora,
    instituciones,
    hospital,
    umf,
    entidadFederativa,
    uploadedDirectories,
    reqDirectories,
    //currentCatalog,
  } = useSelector((state) => state.catalogs);

  let {
    bleedingCurrent,
    bleedingCreated,
    bleedingEdited,
    showText,
    responseError,
  } = useSelector((state) => state.bleeding);

  let { id } = useParams();

  /**
   *
   * Main Functions
   *
   **/
  const setErrorValue = (name, value) => {
    setErrorRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setValueToRequest = (name, value) => {
    setRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValueToRequest(name, value);
    validateRequestField(name, value);
  };

  const onChangeCheck = (name, value, checked) => {
    let assignEnable = checked ? 1 : 0;
    setValueToRequest(name, assignEnable);
    validateRequestField(name, assignEnable);
  };

  const onSelectMedication = (id) => {
    setNav(MENU_OPTION.MEDICATION_ID + btoa(id));
  };

  const onSave = () => {
    console.log("on save", request);

    if (isCreation) {
      dispatch(createBleeding(request));
    } else {
      dispatch(editBleeding(request));
    }

    setSavingInProgress(true);
  };

  useEffect(() => {
    if (user?.signInUserSession?.idToken?.jwtToken) {
      if (!uploadedDirectories && reqDirectories) {
        dispatch(loadDirectories());
      }
    }
  }, [uploadedDirectories, reqDirectories]);

  useEffect(() => {
    if (bleedingCreated) {
      setSavingInProgress(false);
      dispatch(setBleedingWasCreated(false));
    }
  }, [bleedingCreated]);

  useEffect(() => {
    if (bleedingEdited) {
      setSavingInProgress(false);
      dispatch(setBleedingWasEdited(false));
    }
  }, [bleedingEdited]);

  const modalManage = (isOpen) => {
    setSavingInProgress(false);
    dispatch(setShowText(!isOpen ? "" : isOpen));
    if (!responseError) {
      if (isCreation) {
        backToList();
      }
    }
  };

  /**
   *
   * Validations to Request
   *
   **/

  const validateRequestField = (name, value) => {
    switch (name) {
      case HEMO_FIELD.MOTIVO:
        if (!value) {
          setErrorValue(name, "El campo no puede ir vacio");
        } else {
          setErrorValue(name, "");
        }
        break;
      case HEMO_FIELD.FECHA:
        if (
          !value ||
          Object.prototype.toString.call(value) === "[object Date]"
        ) {
          setErrorValue(name, "Seleccione una fecha");
        } else {
          setErrorValue(name, "");
        }
        break;
      case HEMO_FIELD.HORA:
        if (!value) {
          setErrorValue(name, "Seleccione una hora");
        } else {
          setErrorValue(name, "");
        }
        break;

      default:
        break;
    }
  };

  /**
   *
   * Params control
   *
   **/
  useEffect(() => {
    if (id) {
      if (id !== undefined) {
        setParam(atob(id));
        //setCurrentlyInEdition(false);
      }
    }
  }, [id]);

  useEffect(() => {
    if (param) {
      if (param === "creation") {
        //console.log(1, "is a creation", request);
        setIsCreation(true);
      } else {
        //console.log(1, "is an edition", request);
        setIsCreation(false);
        setMandatoryFieldsAreOk(true);
      }
    }
  }, [param]);

  /**
   *
   * Creation Control
   *
   **/
  useEffect(() => {
    if (isCreation !== null && param) {
      if (isCreation) {
        setRequest((prevState) => ({
          ...prevState,
          id_usuario: user.attributes ? user.attributes.email : "",
        }));
      } else {
        setTimeout(() => {
          dispatch(getBleeding(param));
        }, 1200);
      }
    }
  }, [isCreation, param]);

  /**
   *
   * Edition Control
   *
   **/
  useEffect(() => {
    //Si es una edición y los datos del bleedingCurrent se actualizaron
    if (isCreation !== null) {
      if (bleedingCurrent) {
        if (!isCreation) {
          //console.log(3, "Recibe bleedingCurrent", isCreation, bleedingCurrent);

          bleedingCurrent.registro = formatDate(new Date().getTime());
          bleedingCurrent.fecha = formatDate(bleedingCurrent.fecha);
          bleedingCurrent.id_usuario = user.attributes
            ? user.attributes.email
            : "";

          setRequest({ ...bleedingCurrent });
        }
      }
    }
  }, [bleedingCurrent]);

  /**
   *
   * Update mandatory fields
   *
   **/
  useEffect(() => {
    let hasError =
      errorRequest.fecha ||
      errorRequest.hora ||
      errorRequest.id_naturaleza_sangrado ||
      errorRequest.motivo ||
      errorRequest.detalle ||
      errorRequest.id_region_general ||
      errorRequest.id_region_especifica ||
      errorRequest.aplicacion ||
      errorRequest.medicaciones;

    if (!hasError) {
      setMandatoryFieldsAreOk(true);
    } else {
      setMandatoryFieldsAreOk(false);
    }
  }, [errorRequest]);

  useEffect(() => {
    let dataFields =
      request.fecha &&
      request.hora &&
      request.id_naturaleza_sangrado > 0 &&
      request.motivo;

    if (dataFields) {
      setMandatoryFieldsAreOk(true);
    } else {
      setMandatoryFieldsAreOk(false);
    }
  }, [request]);

  /**
   *
   * Start Navigation and loader
   *
   **/
  const reloadAllDirectories = () => {
    dispatch(reloadDirectories());
  };

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  const backToList = () => {
    setNav(MENU_OPTION.BLEEDING);
  };

  useEffect(() => {
    if (isLogged) {
      setRenderPage(true);
    }
  }, [isLogged]);

  useEffect(() => {
    if (renderPage && isLogged) {
      setView(MENU_OPTION.BLEEDING_ID + id);
      setRenderPage(true);
      setShowText("");
    }
  }, [renderPage]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setNav(MENU_OPTION.LOGIN);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <LayoutMain>
      <div className="container-body">
        <div className="container-title">
          <h3 className="title-left">
            {`${isCreation ? "Registrar sangrado" : "Edición de sangrado"}`}
          </h3>
          <div className="btn-right">
            <Button color="accent-color" onClick={() => backToList()}>
              {"Lista de sangrados"}
            </Button>
          </div>
        </div>

        {!request.id_usuario ? (
          <LoaderPage />
        ) : (
          <>
            <div className="form form-profile">
              <div className="container-title container-title--margin-bottom">
                <h3 className="title-left">Datos de evento hemorragico</h3>
                <div className="btn-right">
                  <img
                    className={`sync-catalogs ${
                      uploadedDirectories === CATALOGS_LOADED.CHARGING
                        ? "sync-catalogs--charging"
                        : ""
                    }`}
                    src={icSync}
                    onClick={() => reloadAllDirectories()}
                  />
                </div>
              </div>

              <div className="form-data">
                <div className="form-field">
                  <label> Fecha*</label>
                  <input
                    className="dt"
                    name={HEMO_FIELD.FECHA}
                    value={request.fecha}
                    onChange={onChangeForm}
                    type="date"
                    required
                  />
                  <p className="form-error">{errorRequest.fecha}</p>
                </div>

                <div className="form-field">
                  <label htmlFor="fname">Hora*</label>
                  <input
                    onChange={onChangeForm}
                    className="dt"
                    name={HEMO_FIELD.HORA}
                    value={request.hora || ""}
                    type="time"
                    required
                  />
                  <p className="form-error">{errorRequest.hora}</p>
                </div>

                <div className="form-field">
                  <label>Motivo*</label>
                  <input
                    onChange={onChangeForm}
                    name={HEMO_FIELD.MOTIVO}
                    value={request.motivo || ""}
                    type="text"
                  />
                  <p className="form-error">{errorRequest.motivo}</p>
                </div>
                <div className="form-field">
                  <label>Detalle</label>
                  <input
                    onChange={onChangeForm}
                    className="dt"
                    name={HEMO_FIELD.DETALLE}
                    required
                    value={request.detalle}
                    type="text"
                  />
                  <p className="form-error">{errorRequest.detalle}</p>
                </div>

                <div className="form-field">
                  <label> Naturaleza del sangrado* </label>
                  <select
                    name={HEMO_FIELD.NATURALEZA_SANGRADO}
                    onChange={onChangeForm}
                    defaultValue={request.naturaleza_sangrado}
                  >
                    <option value="0">
                      Seleccione la naturaleza del sangrado
                    </option>
                    {naturalezaSangrado &&
                      naturalezaSangrado.map((row, i) => {
                        return (
                          <option
                            key={i}
                            value={row.id_naturaleza_sangrado}
                            selected={
                              row.id_naturaleza_sangrado ===
                                request.id_naturaleza_sangrado && "selected"
                            }
                          >
                            {row.naturaleza_sangrado}
                          </option>
                        );
                      })}
                  </select>
                  <p className="form-error">
                    {errorRequest.id_naturaleza_sangrado}
                  </p>
                </div>

                <div className="form-field">
                  <label>Región general del sangrado*</label>
                  <select
                    name={HEMO_FIELD.REGION_GENERAL}
                    onChange={onChangeForm}
                    defaultValue={request.id_region_general}
                  >
                    <option value="0">Seleccione la región general</option>
                    {regionesGenerales &&
                      regionesGenerales.map((row, i) => {
                        return (
                          <option
                            key={i}
                            value={row.id_region_general}
                            selected={
                              row.id_region_general ===
                                request.id_region_general && "selected"
                            }
                          >
                            {row.region_general}
                          </option>
                        );
                      })}
                  </select>
                  <p className="form-error">{errorRequest.id_region_general}</p>
                </div>

                <div className="form-field">
                  <label>Región especifica del sangrado*</label>
                  <select
                    name={HEMO_FIELD.REGION_ESPECIFICA}
                    onChange={onChangeForm}
                    defaultValue={request.id_region_especifica}
                  >
                    <option value="0">Seleccione la región específica</option>
                    {regionesEspecificas &&
                      regionesEspecificas.map((row, i) => {
                        if (
                          request.id_region_general == row.id_region_general
                        ) {
                          return (
                            <option
                              key={i}
                              value={row.id_region_especifica}
                              selected={
                                row.id_region_especifica ===
                                  request.id_region_especifica && "selected"
                              }
                            >
                              {row.region_especifica}
                            </option>
                          );
                        }
                      })}
                  </select>
                  <p className="form-error">
                    {errorRequest.id_region_especifica}
                  </p>
                </div>

                <div className="form-field">
                  <label>Aplicación de medicamento</label>
                  <CheckItem
                    value={request.aplicacion || ""}
                    name={HEMO_FIELD.APLICACION}
                    selected={request.aplicacion == 1}
                    onClick={onChangeCheck}
                  >
                    {request.aplicacion == 1 ? "Si se aplico" : "No se aplico"}
                  </CheckItem>

                  <p className="form-error">{errorRequest.aplicacion}</p>
                </div>

                {request?.aplicacion == 0 && (
                  <div className="form-field">
                    <label>¿Por que no aplcio el medicamento?</label>
                    <input
                      name={HEMO_FIELD.MOTIVO_APLICACION}
                      type="text"
                      value={request.motivo_no_aplicacion || ""}
                      onChange={onChangeForm}
                    />
                    <p className="form-error">
                      {errorRequest.motivo_no_aplicacion}
                    </p>
                  </div>
                )}

                <div className="form-field">
                  {request?.medicaciones?.length > 0 &&
                    request?.medicaciones[0] != 0 && (
                      <div className="form-field">
                        <label>Medicaciones</label>
                        {request.medicaciones.map((med) => {
                          return (
                            <div
                              className="form-med"
                              onClick={() =>
                                onSelectMedication(
                                  med.id_medicacion_persona_clinica
                                )
                              }
                            >
                              <p>{med?.motivo}</p>
                              <p>{formatDate(med?.fecha)}</p>
                              <p>{med?.hora}</p>
                            </div>
                          );
                        })}
                        <p className="form-error">
                          {errorRequest.motivo_no_aplicacion}
                        </p>
                      </div>
                    )}

                  <p className="form-error">{errorRequest.medicaciones}</p>
                </div>
              </div>
            </div>

            <div className="submit submit-paciente">
              <Button
                color="tertiary-color"
                disable={savingInProgress || !mandatoryFieldsAreOk}
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
                inProgress={savingInProgress}
              >
                {isCreation ? "Crear evento" : "Actualizar evento"}
              </Button>
            </div>
          </>
        )}
      </div>

      <Modal
        title={showText}
        isOpen={showText.length > 0}
        handleIsOpen={(open) => modalManage(open)}
        btnPositive
        handlePositive={() => {
          modalManage(false);
        }}
      />
    </LayoutMain>
  );
}
