import axiosService from "../../util/axiosService";
import { ACCION } from "../../util/directory.js";
import {
  SHOW_TEXT,
  RESPONSE_ERROR,
  BLEEDING_CURRENT,
  BLEEDING_EDITED,
  BLEEDING_CREATED,
  BLEEDING_SEARCHED,
  BLEEDING_SEARCH_REALIZED,
} from "../_types/bleedingType";
import { PAGINATION } from "../_types/paginationType";
import { catchErrorNetwork } from "../../util/stringer";

let { url_x_sangrado_persona } = require("../../configs/Ambiente.json")["urls"];

export const setShowText = (text) => async (dispatch) => {
  dispatch({ type: SHOW_TEXT, payload: text });
  dispatch({ type: RESPONSE_ERROR, payload: false });
};

export const setBleedingWasCreated = (isValid) => async (dispatch) => {
  dispatch({
    type: BLEEDING_CREATED,
    payload: isValid,
  });
};

export const setBleedingWasEdited = (isValid) => async (dispatch) => {
  dispatch({
    type: BLEEDING_EDITED,
    payload: isValid,
  });
};

export const getBleeding =
  (id_sangrado_persona_clinica) => async (dispatch) => {
    let body = { typeAccion: ACCION.OBTENER_ID, id_sangrado_persona_clinica };
    axiosService({
      type: "post",
      url: url_x_sangrado_persona,
      body,
      success: (res) => {
        let { isSuccess, error, results } = res.data;

        if (isSuccess) {
          if (!error) {
            dispatch({ type: BLEEDING_CURRENT, payload: results[0] });
            dispatch({ type: SHOW_TEXT, payload: "" });
            dispatch({ type: RESPONSE_ERROR, payload: false });
          } else {
            dispatch({ type: BLEEDING_CURRENT, payload: [] });
            dispatch({ type: SHOW_TEXT, payload: error });
            dispatch({ type: RESPONSE_ERROR, payload: true });
          }
        } else {
          dispatch({ type: BLEEDING_CURRENT, payload: [] });
          dispatch({ type: SHOW_TEXT, payload: error });
          dispatch({ type: RESPONSE_ERROR, payload: true });
        }
      },
      fail: (err) => {
        dispatch({ type: BLEEDING_CURRENT, payload: [] });
        dispatch({ type: SHOW_TEXT, payload: catchErrorNetwork(null, err) });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      },
    });
  };

export const setSerchRealized = (value) => async (dispatch) => {
  dispatch({ type: BLEEDING_SEARCH_REALIZED, payload: value });
};

export const getBleedingSearched =
  (chain, page, limit, id_usuario) => async (dispatch) => {
    //chain = !chain ? "&&" : chain;
    let body = {
      typeAccion: ACCION.BUSCAR,
      data: chain,
      page,
      limit,
      id_usuario,
    };
    axiosService({
      type: "post",
      url: url_x_sangrado_persona,
      body,
      success: (res) => {
        let { isSuccess, error, results, total } = res.data;
        if (!isSuccess) {
          if (error.length >= 0) {
            dispatch({ type: PAGINATION, payload: { page: 1, total: 0 } });
            dispatch({ type: BLEEDING_SEARCHED, payload: [] });
            dispatch({ type: BLEEDING_SEARCH_REALIZED, payload: true });
            dispatch({ type: RESPONSE_ERROR, payload: true });
            dispatch({ type: SHOW_TEXT, payload: error });
          }
          return;
        }
        dispatch({ type: PAGINATION, payload: { page: page, total: total } });
        dispatch({ type: BLEEDING_SEARCHED, payload: results });
        dispatch({ type: BLEEDING_SEARCH_REALIZED, payload: true });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({ type: SHOW_TEXT, payload: "" });
      },
      fail: (err) => {
        dispatch({ type: PAGINATION, payload: { page: 1, total: 0 } });
        dispatch({ type: BLEEDING_SEARCHED, payload: [] });
        dispatch({ type: BLEEDING_SEARCH_REALIZED, payload: true });
        dispatch({ type: RESPONSE_ERROR, payload: true });
        dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      },
    });
  };

export const createBleeding = (data) => async (dispatch) => {
  let body = { typeAccion: ACCION.CREAR, ...data };
  axiosService({
    type: "post",
    url: url_x_sangrado_persona,
    body,
    success: (res) => {
      let { isSuccess, error, results, statusCode } = res.data;
      if (isSuccess && !error) {
        dispatch({ type: BLEEDING_CREATED, payload: true });
        dispatch({ type: BLEEDING_CURRENT, payload: results[0] });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({
          type: SHOW_TEXT,
          payload: `El sangrado fue creado`,
        });
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

export const editBleeding = (data) => async (dispatch) => {
  let body = { typeAccion: ACCION.MODIFICAR, ...data };
  axiosService({
    type: "post",
    url: url_x_sangrado_persona,
    body,
    success: (res) => {
      let { isSuccess, error, results } = res.data;
      if (isSuccess && !error) {
        dispatch({ type: BLEEDING_EDITED, payload: true });
        dispatch({ type: BLEEDING_CURRENT, payload: results[0] });
        dispatch({ type: RESPONSE_ERROR, payload: false });
        dispatch({
          type: SHOW_TEXT,
          payload: `El sangrado ha sido actualizado`,
        });
      } else {
        dispatch({ type: SHOW_TEXT, payload: error });
        dispatch({ type: RESPONSE_ERROR, payload: true });
      }
    },
    fail: (err) => {
      dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
      dispatch({ type: RESPONSE_ERROR, payload: true });
    },
  });
};

// export const deleteUserInCreation =
//   (id_usuario, msgSuccess) => async (dispatch) => {
//     axiosService({
//       type: "post",
//       url: url_post_user,
//       body: { typeAccion: ACCION.BORRAR, id_usuario: id_usuario },
//       success: (res) => {
//         let { isSuccess, error, results, statusCode } = res.data;
//         if (isSuccess && !error) {
//           if (msgSuccess) {
//             dispatch({ type: SHOW_TEXT, payload: msgSuccess });
//             dispatch({ type: RESPONSE_ERROR, payload: true });
//           } else {
//             dispatch({ type: SHOW_TEXT, payload: results });
//             dispatch({ type: RESPONSE_ERROR, payload: false });
//           }
//         } else {
//           dispatch({
//             type: SHOW_TEXT,
//             payload: catchErrorNetwork(statusCode, JSON.stringify(error)),
//           });
//           dispatch({ type: RESPONSE_ERROR, payload: true });
//         }
//       },
//       fail: (err) => {
//         dispatch({ type: SHOW_TEXT, payload: "Error de conexión" });
//         dispatch({ type: RESPONSE_ERROR, payload: true });
//       },
//     });
//   };
