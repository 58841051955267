import { useEffect, useState, useContext } from "react";
//import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import bgImage from "../../assets/static/bg_login.jpg";
import decor from "../../assets/static/decor.svg";
import logo from "../../assets/static/logo_brand.png";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import AuthContext from "../../context/AuthContext";
//import NavContext from "../../context/NavContext";
import { MENU_OPTION } from "../../util/directory";
import LoaderPage from "../../components/LoaderPage";

export default function Activation() {
  let navigate = useNavigate();
  //const dispatch = useDispatch();
  let { resError } = useSelector((state) => state.auth);
  const { confirmUser, isLogged, sendCodeReset } = useContext(AuthContext);
  const [renderPage, setRenderPage] = useState(false);
  const [info, setInfo] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const getCode = () => {
    sendCodeReset(info);
    setInProgress(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInfo((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInProgress(true);
    confirmUser(info);
  };

  useEffect(() => {
    if (resError.isError === false && isLogged) {
      navigate(MENU_OPTION.MAIN);
    } else if (resError.isError === true && !isLogged) {
      //console.log("error", resError.msg);
      setModalText(resError.msg);
      setModalIsOpen(true);
    }
    /*if (resError.isError === true) {
      setModalText(resError.msg);
      setModalIsOpen(true);
    }*/
  }, [resError, isLogged]);

  useEffect(() => {
    //console.log("is logged", isLogged);
    if (isLogged) {
      navigate(MENU_OPTION.MAIN);
    }
  }, [isLogged]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setRenderPage(true);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <>
      <div className="container-login">
        <div className="panel-back">
          <img src={bgImage} className="bg-image" />
          <img src={logo} className="logo-image" />
          <img src={decor} className="decor-image" />
        </div>
        <div className="panel-front">
          <div className="shape shape-center">
            <label>Introduce tu correo</label>
            <input
              type="text"
              name="username"
              className="inpt"
              onChange={handleChange}
            />

            <label>Código</label>
            <input
              type="text"
              className="inpt"
              name="code"
              onChange={handleChange}
            />
            <Button
              color="primary-color"
              onClick={handleSubmit}
              inProgress={inProgress}
            >
              Confirmar
            </Button>
            <Button
              color="secondary-color"
              onClick={() => getCode()}
              inProgress={inProgress}
              disable={!info.username}
            >
              Reenviar código
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title={modalText}
        isOpen={modalIsOpen}
        handleIsOpen={(isOpen) => setModalIsOpen(isOpen)}
        handleNeutro={() => setInProgress(false)}
        btnNeutro
      />
    </>
  );
}
