import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  signOut,
  signIn,
  confirmSignUp,
  getCodeReset,
  setShowText,
} from "../redux/_actions/authAction";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isLogged, user } = useSelector((state) => state.auth);

  const logIn = (info) => {
    dispatch(signIn(info));
  };

  const logOut = () => {
    dispatch(signOut());
  };

  const confirmUser = (info) => {
    dispatch(confirmSignUp(info));
  };

  const sendCodeReset = (info) => {
    dispatch(getCodeReset(info));
  };

  const setTextInError = (text) => {
    dispatch(setShowText(text));
  };

  useEffect(() => {
    if (!isLogged) {
      dispatch(getUser());
    }
  }, []);

  const data = {
    logOut,
    logIn,
    confirmUser,
    sendCodeReset,
    setTextInError,
    isLogged,
    user,
  };
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
