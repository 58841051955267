import { CATALOG_OBJ } from "../../util/prototypes";

export const CONTACT_TYPES = CATALOG_OBJ.CONTACT_TYPE.NAME;
export const REGIONES_GENERALES = CATALOG_OBJ.REGIONES_GENERALES.NAME;
export const REGIONES_ESPECIFICAS = CATALOG_OBJ.REGIONES_ESPECIFICAS.NAME;
export const NATURALEZA_SANGRADO = CATALOG_OBJ.NATURALEZA_SANGRADO.NAME;
export const TRATAMIENTO = CATALOG_OBJ.TRATAMIENTO.NAME;
export const ESQUEMA = CATALOG_OBJ.ESQUEMA.NAME;
export const ARTROPATIA = CATALOG_OBJ.ARTROPATIAS.NAME;
export const ASEGURADORA = CATALOG_OBJ.ASEGURADORAS.NAME;
export const DIAGNOSTICOS = CATALOG_OBJ.DIAGNOSTICO.NAME;
export const DIRECCION = CATALOG_OBJ.DIRECCION.NAME;
export const ENTIDAD_FEDERATIVA = CATALOG_OBJ.ENTIDADES_FEDERATIVAS.NAME;
export const ESCOLARIDAD = CATALOG_OBJ.ESCOLARIDAD.NAME;
export const ESTATUS_SS = CATALOG_OBJ.ESTATUS_SS.NAME;
export const INSTITUCIONES = CATALOG_OBJ.INSTITUCIONES.NAME;
export const HOSPITAL = CATALOG_OBJ.HOSPITALES.NAME;
export const PARENTESCO = CATALOG_OBJ.PARENTESCO.NAME;
export const PROFESIONAL_SALUD = CATALOG_OBJ.PROFESIONALES_SALUD.NAME;
export const SEVERIDAD = CATALOG_OBJ.SEVERIDAD.NAME;
export const TIPO_SANGRE = CATALOG_OBJ.TIPO_SANGRE.NAME;
export const COAGULOPATIA = CATALOG_OBJ.COAGULOPATIAS.NAME;
export const UMF = CATALOG_OBJ.UMF.NAME;
export const SANGRADO = CATALOG_OBJ.SANGRADO.NAME;
export const MEDICACION = CATALOG_OBJ.MEDICACION.NAME;
export const UPLOADED_DIRECTORIES = "UPLOADED_DIRECTORIES";
export const REQ_DIRECTORIES = "REQ_DIRECTORIES";
export const CURRENT_CATALOG = "CURRENT_CATALOG";
export const CATALOG_CREATED = "CATALOG_CREATED";
export const CATALOG_EDITED = "CATALOG_EDITED";
export const SHOW_TEXT = "SHOW_TEXT";
export const RESPONSE_ERROR = "RESPONSE_ERROR";
