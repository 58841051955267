import spinGray from "../assets/static/spin-gray.gif";
import spinWhite from "../assets/static/spin-white.gif";
import { React, useState, useEffect, useRef } from "react";
import { MENU_OPTION } from "../util/directory";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default function LoaderPage(props) {
  const [counter, setCounter] = useState(0);
  let maxCounter = 2;

  useInterval(() => {
    setCounter(counter + 1);
  }, 1000);

  useEffect(() => {
    props.action && props.action(counter, maxCounter);
  }, [counter]);

  return (
    <div className="loader">
      <p>
        <img src={props.color == "white" ? spinWhite : spinGray} alt="" />
      </p>
    </div>
  );
}
