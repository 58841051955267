import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
//import { useDispatch } from "react-redux";
//import { signOut } from "../redux/_actions/authAction";
import icMenuNormal from "../assets/static/ic_menu_gray.svg";
import icMenuActive from "../assets/static/ic_menu_red.svg";
import icClose from "../assets/static/ic_menu_gray.svg";
import icUser from "../assets/static/ic_user.svg";
import logoBrand from "../assets/static/logo_brand.png";
import Modal from "../components/Modal";
import AuthContext from "../context/AuthContext";
import NavContext from "../context/NavContext";
import { MENU_OPTION } from "../util/directory";

function Header() {
  let navigate = useNavigate();
  const { logOut, isLogged, user } = useContext(AuthContext);
  const { currentView, setView } = useContext(NavContext);
  const [isOpen, setIsOpen] = useState(false);
  const [closeSession, setCloseSession] = useState(false);

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  useEffect(() => {
    if (!isLogged) {
      setNav(MENU_OPTION.LOGIN);
    }
  }, [isLogged]);

  return (
    <>
      <div
        className={`header-mobile header-mobile--${isOpen ? "open" : "close"}`}
      >
        <div className={"header-overlay"}>
          <img
            src={icClose}
            className={"header-drop"}
            onClick={() => setIsOpen(false)}
          />
          <p
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
              setNav(MENU_OPTION.BLEEDING);
            }}
          >
            Eventos hemorrágicos
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
              setNav(MENU_OPTION.BLEEDING_ID + btoa("creation"));
            }}
          >
            Añadir sangrado
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
              setNav(MENU_OPTION.MEDICATION);
            }}
          >
            Administración de medicinas
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
              setNav(MENU_OPTION.MEDICATION_ID + btoa("creation"));
            }}
          >
            Añadir administración de medicamentos
          </p>
          <p
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
              setCloseSession(true);
            }}
          >
            Cerrar sesión
          </p>
        </div>
      </div>
      <header className={"header"}>
        <div className={"header-brand"}>
          <img
            className="header-logo"
            src={logoBrand}
            onClick={(e) => {
              e.preventDefault();
              setNav(MENU_OPTION.MAIN);
            }}
          />
          <img
            className={`header-menu `}
            src={isOpen ? icMenuNormal : icMenuActive}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          />
        </div>
        <ul className={"header-selection"}>
          <li
            className={"header-item"}
            onClick={(e) => {
              e.preventDefault();
              setNav(MENU_OPTION.BLEEDING);
            }}
          >
            <p
              className={`header-option ${
                currentView?.includes(MENU_OPTION.BLEEDING) && "option--active"
              }`}
            >
              Eventos hemorrágicos
            </p>
          </li>
          <li
            className={"header-item"}
            onClick={(e) => {
              e.preventDefault();
              setNav(MENU_OPTION.MEDICATION);
            }}
          >
            <p
              className={`header-option ${
                currentView?.includes(MENU_OPTION.MEDICATION) &&
                "option--active"
              }`}
            >
              Administración de medicinas
            </p>
          </li>
          <li
            className={"header-item"}
            onClick={(e) => {
              e.preventDefault();
              setNav(MENU_OPTION.PROFILE);
            }}
          >
            <p
              className={`header-option ${
                currentView?.includes(MENU_OPTION.PROFILE) && "option--active"
              }`}
            >
              <img src={icUser} className="user-icon" />
              <span>{user?.attributes?.name || ""}</span>
            </p>
          </li>
          <li className={"header-line"}></li>
          <li className={"header-close"} onClick={() => setCloseSession(true)}>
            <p className={"close-option"}>Cerrar Sesión</p>
          </li>
        </ul>
      </header>
      <Modal
        title={"¿Esta seguro de cerrar su sesión?"}
        isOpen={closeSession}
        handleIsOpen={(open) => setCloseSession(open)}
        handlePositive={() => logOut()}
        btnPositive
        btnNegative
      />
    </>
  );
}

export default Header;
