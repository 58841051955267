export const CONTACT_TYPE_POJO = {
  id_tipo_contacto: 0,
  tipo_contacto: "",
  enable: 0,
};

export const REGIONES_GENERALES_POJO = {
  id_region_general: 0,
  region_general: "",
  enable: 0,
};

export const REGIONES_ESPECIFICAS_POJO = {
  id_region_especifica: 0,
  region_especifica: "",
  id_region_general: 0,
  enable: 0,
};

export const ARTROPATIAS_POJO = {
  id_artropatia: 0,
  artropatia: "",
  id_region_general: 0,
  enable: 0,
};

export const ASEGURADORAS_POJO = {
  id_aseguradora: 0,
  aseguradora: "",
  enable: 0,
};

export const DIAGNOSTICO_POJO = {
  id_diagnostico: 0,
  diagnostico: "",
  enable: 0,
};

export const ENTIDADES_FEDERATIVAS_POJO = {
  entidad: "",
  id_entidad: 0,
  abreviatura_entidad_federativa: "",
  enable: 0,
};
export const ESCOLARIDAD_POJO = {
  id_escolaridad: 0,
  escolaridad: "",
  enable: 0,
};
export const ESTATUS_SS_POJO = {
  id_estatus_ss: 0,
  estatus_ss: "",
  enable: 0,
};
export const INSTITUCIONES_POJO = {
  id_institucion: 0,
  institucion: "",
  abv_institucion: "",
  enable: 0,
};
export const HOSPITALES_POJO = {
  id_hospital: 0,
  id_institucion: 0,
  hospital: "",
  institucion: "",
  abv_institucion: "",
  cp: "",
  asentamiento: "",
  id_estado_residencia: "",
  municipio: "",
  calle: "",
  num_ext: "",
  num_int: "",
  enable: 0,
};
export const PARENTESCO_POJO = {
  id_parentesco: 0,
  parentesco: "",
  enable: 0,
};
export const PROFESIONAL_SALUD_POJO = {
  id_profesional_salud: 0,
  profesional_salud: "",
  enable: 0,
};
export const SEVERIDAD_POJO = {
  enable: 0,
  id_severidad: 0,
  severidad: "",
};
export const TIPO_SANGRE_POJO = {
  id_tipo_sangre: 0,
  tipo_sangre: "",
  enable: 0,
};

export const COAGULOPATIAS_POJO = {
  id_coagulopatia: 0,
  id_diagnostico: 0,
  coagulopatia: "",
  diagnostico: "",
  enable: 0,
};

export const UMF_POJO = {
  id_umf: 0,
  umf: "",
  id_hospital: 0,
  cp: "",
  id_estado_residencia: "",
  asentamiento: "",
  municipio: "",
  calle: "",
  num_ext: "",
  num_int: "",
  enable: 0,
};

export const NATURALEZA_SANGRADO = {
  id_naturaleza_sangrado: 0,
  naturaleza_sangrado: "",
  enable: 0,
};

export const TRATAMIENTO = {
  id_tratamiento: 0,
  tratamiento: "",
  enable: 0,
};

export const ESQUEMA = {
  id_esquema: 0,
  esquema: "",
  enable: 0,
};

export const MEDICACION_POJO = {
  id_persona: 0,
  id_medicacion: 0,
  fecha: "",
  hora: "",
  motivo: "",
  tratamiento: 0,
  esquema: 0,
  viales: "",
  unidades: "",
  lote: "",
  caducidad: "",
  sangrado: {},
  registro: "",
  enable: 0,
};

export const HEMORRAGIA_POJO = {
  id_persona: 0,
  id_sangrado_persona_clinica: 0,
  fecha: "",
  hora: "",
  motivo: "",
  naturaleza_sangrado: "",
  detalle: "",
  region_general: "",
  region_especifica: "",
  aplicacion: false,
  motivo_no_aplicacion: "",
  medicaciones: [],
  registro: "",
  enable: 0,
};

export const CATALOG_OBJ = {
  CONTACT_TYPE: { NAME: "Tipo de contacto" },
  REGIONES_GENERALES: { NAME: "Región general" },
  REGIONES_ESPECIFICAS: { NAME: "Región especifica" },
  ARTROPATIAS: { NAME: "Artropatías" },
  ASEGURADORAS: { NAME: "Aseguradoras" },
  DIAGNOSTICO: { NAME: "Diagnósticos" },
  ENTIDADES_FEDERATIVAS: { NAME: "Entidades Federativas" },
  ESCOLARIDAD: { NAME: "Escolaridad" },
  ESTATUS_SS: { NAME: "Estatus de Seguridad Social" },
  INSTITUCIONES: { NAME: "Instituciones" },
  HOSPITALES: { NAME: "Hospitales" },
  PARENTESCO: { NAME: "Parentesco" },
  PROFESIONALES_SALUD: { NAME: "Profesionales de la salud" },
  SEVERIDAD: { NAME: "Severidad" },
  TIPO_SANGRE: { NAME: "Tipo de sangre" },
  COAGULOPATIAS: { NAME: "Coagulopatías" },
  UMF: { NAME: "UMF" },
  DIRECCION: { NAME: "Dirección" },
  NATURALEZA_SANGRADO: { NAME: "Naturaleza del sangrado" },
  TRATAMIENTO: { NAME: "Tratamiento administrado" },
  ESQUEMA: { NAME: "Esquema de tratamiento" },
  SANGRADO: { NAME: "Hemorragia" },
  MEDICACION: { NAME: "Medicación" },
};

export const CATALOGS = [
  CATALOG_OBJ.SEVERIDAD.NAME,
  CATALOG_OBJ.ESCOLARIDAD.NAME,
  CATALOG_OBJ.PARENTESCO.NAME,
  CATALOG_OBJ.CONTACT_TYPE.NAME,
  CATALOG_OBJ.TIPO_SANGRE.NAME,
  CATALOG_OBJ.ENTIDADES_FEDERATIVAS.NAME,
  CATALOG_OBJ.ESTATUS_SS.NAME,
  CATALOG_OBJ.REGIONES_GENERALES.NAME,
  CATALOG_OBJ.REGIONES_ESPECIFICAS.NAME,
  CATALOG_OBJ.ARTROPATIAS.NAME,
  CATALOG_OBJ.DIAGNOSTICO.NAME,
  CATALOG_OBJ.COAGULOPATIAS.NAME,
  CATALOG_OBJ.ASEGURADORAS.NAME,
  CATALOG_OBJ.INSTITUCIONES.NAME,
  CATALOG_OBJ.HOSPITALES.NAME,
  CATALOG_OBJ.UMF.NAME,
  CATALOG_OBJ.PROFESIONALES_SALUD.NAME,
  CATALOG_OBJ.NATURALEZA_SANGRADO.NAME,
  CATALOG_OBJ.TRATAMIENTO.NAME,
  CATALOG_OBJ.ESQUEMA.NAME,
  CATALOG_OBJ.SANGRADO.NAME,
  CATALOG_OBJ.MEDICACION.NAME,
];

export const getProtoRequest = (type) => {
  switch (type) {
    case CATALOG_OBJ.REGIONES_GENERALES.NAME:
      return REGIONES_GENERALES_POJO;
    case CATALOG_OBJ.REGIONES_ESPECIFICAS.NAME:
      return REGIONES_ESPECIFICAS_POJO;
    case CATALOG_OBJ.ARTROPATIAS.NAME:
      return ARTROPATIAS_POJO;
    case CATALOG_OBJ.ASEGURADORAS.NAME:
      return ASEGURADORAS_POJO;
    case CATALOG_OBJ.DIAGNOSTICO.NAME:
      return DIAGNOSTICO_POJO;
    case CATALOG_OBJ.ENTIDADES_FEDERATIVAS.NAME:
      return ENTIDADES_FEDERATIVAS_POJO;
    case CATALOG_OBJ.ESCOLARIDAD.NAME:
      return ESCOLARIDAD_POJO;
    case CATALOG_OBJ.ESTATUS_SS.NAME:
      return ESTATUS_SS_POJO;
    case CATALOG_OBJ.INSTITUCIONES.NAME:
      return INSTITUCIONES_POJO;
    case CATALOG_OBJ.HOSPITALES.NAME:
      return HOSPITALES_POJO;
    case CATALOG_OBJ.PARENTESCO.NAME:
      return PARENTESCO_POJO;
    case CATALOG_OBJ.PROFESIONALES_SALUD.NAME:
      return PROFESIONAL_SALUD_POJO;
    case CATALOG_OBJ.SEVERIDAD.NAME:
      return SEVERIDAD_POJO;
    case CATALOG_OBJ.TIPO_SANGRE.NAME:
      return TIPO_SANGRE_POJO;
    case CATALOG_OBJ.COAGULOPATIAS.NAME:
      return COAGULOPATIAS_POJO;
    case CATALOG_OBJ.UMF.NAME:
      return UMF_POJO;
    case CATALOG_OBJ.CONTACT_TYPE.NAME:
      return CONTACT_TYPE_POJO;
    case CATALOG_OBJ.NATURALEZA_SANGRADO.NAME:
      return HEMORRAGIA_POJO;
    case CATALOG_OBJ.TRATAMIENTO.NAME:
      return HEMORRAGIA_POJO;
    case CATALOG_OBJ.ESQUEMA.NAME:
      return HEMORRAGIA_POJO;
    case CATALOG_OBJ.SANGRADO.NAME:
      return HEMORRAGIA_POJO;
    case CATALOG_OBJ.MEDICACION.NAME:
      return MEDICACION_POJO;
    default:
      break;
  }
};
