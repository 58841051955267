import React from "react";

export default function NotFound() {
  return (
    <div style={{ color: "black", textAlign: "center" }}>
      <h3>404 page not found</h3>
      <p>We are sorry but the page you are looking for does not exist.</p>
    </div>
  );
}
