import { React, useEffect, useState } from "react";
import spinGray from "../assets/static/spin-gray.gif";
import spinWhite from "../assets/static/spin-white.gif";
import Button from "./Button";
import CheckItem from "./CheckItem";
import LoaderPage from "./LoaderPage";
import icOpen from "../assets/static/ic_arrow_white_down.svg";
import icClose from "../assets/static/ic_arrow_red_down.svg";
import icVwPrev from "../assets/static/ic_visibility_prev.svg";
import icVwOn from "../assets/static/ic_visibility_on.svg";
import icVwOff from "../assets/static/ic_visibility_off.svg";
import icVwPrevActive from "../assets/static/ic_visibility_prev_white.svg";
import icVwOnActive from "../assets/static/ic_visibility_on_white.svg";
import icVwOffActive from "../assets/static/ic_visibility_off_white.svg";
import { changeSpecialChars } from "../util/stringer";

/**
  *
  * 
  * Properties. Estructura que necesita este componente para poder funcionar
  * 
  data: 
    {
      id_FIELD: id,
      FIELD: field name,
      selected: boolean attribute,
    }
 *
 *
**/
export default function InputMultipleSelection(props) {
  const [textToFind, setTextToFind] = useState("");
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [allItems, setAllItems] = useState(false);
  const [seeBadges, setSeeBadges] = useState(1);
  const [openList, setOpenList] = useState(false);

  //Filter by text input
  const applyFilterToList = (textVal) => {
    if (list?.length > 0) {
      const filteredItems = list.filter((item) => {
        let itemWord = changeSpecialChars(item[props.value]?.toLowerCase());
        return itemWord.match(textVal.toLowerCase());
      });
      setFilterList(filteredItems);
    }
  };

  useEffect(() => {
    applyFilterToList(textToFind || "");
  }, [textToFind]);

  const handleChangeInputText = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTextToFind(value);
  };

  const handleSearching = (e) => {
    e.preventDefault();
    const { value } = e.target;
    props.onChangePrediction(value);
  };

  //Assign list, filtered list and  by check All Items
  const changeToAllItems = (checked) => {
    setOpenList(false);
    list.map((row, i) => {
      onSelection(row[props.name], row[props.value], checked);
    });
  };

  const getIfAllItemsSelected = () => {
    if (list?.length > 0) {
      let getSelections = list.map((row, i) => row.selected == true);
      let counter = getSelections.filter(Boolean).length;
      return counter == list.length;
    }

    return false;
  };

  useEffect(() => {
    props.onChangeData(props.name, itemsSelected);
  }, [itemsSelected]);

  const onItemSelection = (id, value, checked) => {
    let existBadge = itemsSelected.find((item) => item.id == id);

    if (checked) {
      if (!existBadge) {
        setItemsSelected((prevState) => {
          return [...prevState, { id, value }];
        });
      }
    } else {
      if (existBadge) {
        setItemsSelected((prevState) => {
          return prevState.filter((obj, i) => {
            return obj.id != id;
          });
        });
      }
    }
  };

  useEffect(() => {
    if (list) {
      list.map((item) => {
        onItemSelection(item[props.name], item[props.value], item.selected);
      });

      setAllItems(getIfAllItemsSelected());

      applyFilterToList(textToFind);
    }
  }, [list]);

  //Assign list, filtered list and  by selection
  const onSelection = (id, value, checked) => {
    console.log(id, value, checked);
    if (props.isNotSelectable == true) {
      setList(
        filterList.map((item) => {
          if (item[props.name] === id) {
            return { ...item, selected: checked };
          }
          item.selected = false;
          return item;
        })
      );
    } else {
      setList(
        filterList.map((item) => {
          if (item[props.name] === id) {
            return { ...item, selected: checked };
          }
          return item;
        })
      );
    }
  };

  //set data of props.data or when has changes the data list
  useEffect(() => {
    if (
      props.data?.length > 0 &&
      JSON.stringify(props.data) != JSON.stringify(list)
    ) {
      console.log("set list data", props.data);
      setList(
        props.data.map((row, i) => {
          return { ...row };
        })
      );
    } else {
      console.log("set empty list");
      setList([]);
    }
  }, [props.data]);

  return (
    <div className={"mtin"}>
      <label className={"mtin-title"}>{props.title}</label>
      <div className={"mtin-content"}>
        <div className={"mtin-combobox"}>
          {props.isLoading == true && <LoaderPage />}
          <input
            className={`mtin-inpt`}
            value={props.isPredictive ? props.predictiveText : textToFind}
            onFocus={(e) => setOpenList(true)}
            //onBlur={(e) => setOpenList(false)}
            onChange={(e) =>
              props.isPredictive ? handleSearching(e) : handleChangeInputText(e)
            }
          />

          <Button
            color={"primary-color"}
            onClick={() => setOpenList(!openList)}
          >
            <img
              className={`arrow-img--${openList ? "open" : "close"}`}
              src={icOpen}
            />
          </Button>
        </div>

        <div className="mtin-control">
          {props.isNotSelectable == true ? (
            "Selecciona una opción"
          ) : (
            <CheckItem
              value={"Todo"}
              name={"todo"}
              selected={allItems}
              onClick={() => {
                changeToAllItems(!allItems);
              }}
            >
              {"Todo"}
            </CheckItem>
          )}

          <Button
            color={seeBadges == 1 ? "secondary-color" : "white"}
            onClick={() => {
              setSeeBadges(1);
            }}
          >
            <img
              className={`control-img`}
              src={seeBadges == 1 ? icVwPrevActive : icVwPrev}
            />
          </Button>
          <Button
            color={seeBadges == 2 ? "secondary-color" : "white"}
            onClick={() => {
              setSeeBadges(2);
            }}
          >
            <img
              className={`control-img`}
              src={seeBadges == 2 ? icVwOnActive : icVwOn}
            />
          </Button>
          <Button
            color={seeBadges == 3 ? "secondary-color" : "white"}
            onClick={() => {
              setSeeBadges(3);
            }}
          >
            <img
              className={`control-img`}
              src={seeBadges == 3 ? icVwOffActive : icVwOff}
            />
          </Button>
        </div>

        <div
          className={`mtin-list mtin-list${!openList ? "--hide" : "--show"}`}
        >
          {filterList?.length > 0 ? (
            filterList.map((row, i) => {
              //props.nonMatchNameField == true;
              let name = row[props.name];
              let value = row[props.value];
              //console.log("imprime", name, value);
              return (
                <CheckItem
                  key={i}
                  value={value}
                  name={name}
                  selected={row.selected}
                  onClick={(id, value, checked) =>
                    onSelection(id, value, checked)
                  }
                >
                  {value}
                </CheckItem>
              );
            })
          ) : (
            <p className="mtin-list--empty">No hay coincidencias</p>
          )}
        </div>
      </div>

      {itemsSelected?.length > 0 && (
        <div
          className={`mtin-badgelist mtin-badgelist${
            seeBadges === 1 ? "--prev" : seeBadges === 2 ? "--full" : "--close"
          }`}
        >
          {itemsSelected.map((row, i) => {
            return (
              <span
                key={i}
                className="badge"
                onClick={(e) => {
                  e.preventDefault();
                  onSelection(row.id, row.value, false);
                }}
              >
                {row.value}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}
