import { DELETED_IS_SUCCESS } from "../_types/documentsType";

const initialState = {
  deletedIsSuccess: false,
};

export default function preregistroReducer(state = initialState, action) {
  switch (action.type) {
    case DELETED_IS_SUCCESS:
      return { ...state, deletedIsSuccess: action.payload };

    default:
      return { ...state };
  }
}
