import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import { useSelector } from "react-redux";
import { getCodeReset, forgotPassword } from "../../redux/_actions/authAction";

export const ResetPassword = ({ setOpenModalResetPass }) => {
  const dispatch = useDispatch();
  const { sentcode } = useSelector((state) => state.auth);

  const [info, setInfo] = useState({});
  const [inProgress, setInProgress] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInfo((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInProgress(true);
    dispatch(forgotPassword(info))
      .then(() => {
        setInProgress(false);
        setOpenModalResetPass(false);
      })
      .catch(() => {
        setInProgress(false);
      });
  };

  const getCode = () => {
    dispatch(getCodeReset(info))
      .then(() => {
        setInProgress(false);
      })
      .catch(() => {
        setInProgress(false);
      });
  };

  const checkNumbers = new RegExp(/[0-9]/);
  const checkSpecial = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  const checkMayus = new RegExp(/[A-Z]/);
  const checkMins = new RegExp(/[a-z]/);

  return (
    <>
      {sentcode ? (
        <div className="container-login">
          <div className="panel-front">
            <div className="shape shape-center">
              <label>Usuario</label>
              <input
                className="inpt"
                type="email"
                name="username"
                onChange={handleChange}
              />
              <label>Code FHRM</label>
              <input
                className="inpt"
                type="text"
                name="code"
                onChange={handleChange}
              />
              <label>Nueva Contraseña</label>
              <input
                className="inpt"
                type="password"
                name="newPassword"
                onChange={handleChange}
              />
              <ul>
                {info.newPassword && info.newPassword.length < 8 && (
                  <li style={{ color: "red" }}>Mínimo 8 caracteres</li>
                )}
                {info.newPassword && !checkNumbers.test(info.newPassword) && (
                  <li style={{ color: "red" }}>Contiene un número</li>
                )}
                {info.newPassword && !checkSpecial.test(info.newPassword) && (
                  <li style={{ color: "red" }}>
                    Contiene un carácter especial
                  </li>
                )}
                {info.newPassword && !checkMayus.test(info.newPassword) && (
                  <li style={{ color: "red" }}>Contiene una letra mayúscula</li>
                )}
                {info.newPassword && !checkMins.test(info.newPassword) && (
                  <li style={{ color: "red" }}>Contiene una letra minúscula</li>
                )}
              </ul>
              <Button
                color="primary-color"
                onClick={handleSubmit}
                inProgress={inProgress}
                disable={!info.username || !info.code || !info.newPassword}
              >
                Cambiar contraseña
              </Button>
              <Button
                color="primary-color"
                inProgress={inProgress}
                onClick={() => dispatch(getCodeReset({}))}
              >
                Reenviar código
              </Button>
              <Button
                color="white"
                inProgress={inProgress}
                onClick={() => setOpenModalResetPass(false)}
              >
                Regresar a inicio de sesión
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-login">
          <div className="panel-front">
            <div className="shape shape-center">
              <label>Usuario</label>
              <input
                className="inpt"
                type="email"
                name="username"
                onChange={handleChange}
              />
              <Button
                color="primary-color"
                onClick={() => getCode()}
                inProgress={inProgress}
                disable={!info.username}
              >
                Obtener código de restablecimiento
              </Button>
              <Button
                color="white"
                inProgress={inProgress}
                onClick={() => setOpenModalResetPass(false)}
              >
                Regresar a inicio de sesión
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
