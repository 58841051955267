import {
  REPORTE_DINAMICO,
  REPORTE_DINAMICO_ERROR,
  SHOW_TEXT,
} from "../_types/reporteDinamicoTypes";

const initialState = {
  reporte_dinamico: [],
  url_reporte: "",
  cargando: false,
  error: [],
  showText: [],
};

export default function reporteDinamicoReducer(state = initialState, action) {
  switch (action.type) {
    case REPORTE_DINAMICO:
      const { reporte_dinamico, cargando } = action.payload;
      return {
        ...state,
        reporte_dinamico: reporte_dinamico,
        cargando: cargando,
      };
    case "URL_REPORTE":
      return { ...state, url_reporte: action.payload };
    case REPORTE_DINAMICO_ERROR:
      return { ...state, error: action.payload };
    case SHOW_TEXT:
      return { ...state, showText: action.payload };
    default:
      return { ...state };
  }
}
