import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import bgImage from "../../assets/static/bg_login.svg";
import decor from "../../assets/static/decor.svg";
import logo from "../../assets/static/logo_brand.png";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import AuthContext from "../../context/AuthContext";
import NavContext from "../../context/NavContext";
import { MENU_OPTION, NETWORK_ERRORS } from "../../util/directory";
import LoaderPage from "../../components/LoaderPage";
import ResetPassword from "../authentication/ResetPassword";

export default function Login(props) {
  let navigate = useNavigate();
  let { resError } = useSelector((state) => state.auth);
  const { logIn, setTextInError } = useContext(AuthContext);
  const { setView } = useContext(NavContext);
  const { isLogged } = useSelector((state) => state.auth);
  const [renderPage, setRenderPage] = useState(false);
  const [info, setInfo] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [openModalResetPass, setOpenModalResetPass] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInfo((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInProgress(true);
    //enviar por axios el usuario y contraseña , previamente validados
    logIn(info);
  };

  const handleRestoreAccount = () => {
    //event.preventDefault();
    setOpenModalResetPass(true);
  };

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  const handleVerifyAccount = () => {
    setNav(MENU_OPTION.ACTIVATION);
  };

  useEffect(() => {
    if (resError.isError === false && isLogged) {
      setNav(MENU_OPTION.MAIN);
    } else if (resError.isError === true && !isLogged) {
      setModalText(resError.msg);
      setModalIsOpen(true);
    }
  }, [resError, isLogged]);

  useEffect(() => {
    //console.log("is logged", isLogged);
    if (isLogged) {
      setNav(MENU_OPTION.MAIN);
    }
  }, [isLogged]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setRenderPage(true);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <>
      <div className="container-login">
        <div className="panel-back">
          <img src={bgImage} className="bg-image" />
          <img src={logo} className="logo-image" />
          <img src={decor} className="decor-image" />
        </div>
        <div className="panel-front">
          {!openModalResetPass ? (
            <>
              <div className="shape shape-top">
                <label>Usuario</label>
                <input
                  className="inpt"
                  type="email"
                  name="user"
                  onChange={handleChange}
                />
                <label>Contraseña</label>
                <input
                  className="inpt"
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
                <Button
                  color="primary-color"
                  onClick={handleSubmit}
                  inProgress={inProgress}
                >
                  Iniciar sesión
                </Button>
                <a
                  className="link"
                  onClick={() => handleRestoreAccount()}
                  disable={inProgress}
                >
                  ¿Olvido su contraseña?
                </a>
              </div>
              <div className="shape shape-bottom">
                <label>
                  ¿Tiene una cuenta nueva o llego un código a tu correo o
                  teléfono?
                </label>
                <Button
                  color="tertiary-color"
                  onClick={() => handleVerifyAccount()}
                  disable={inProgress}
                >
                  Activar cuenta
                </Button>
              </div>
            </>
          ) : (
            <ResetPassword setOpenModalResetPass={setOpenModalResetPass} />
          )}
        </div>
      </div>
      <Modal
        title={modalText}
        isOpen={modalIsOpen}
        handleIsOpen={(isOpen) => setModalIsOpen(isOpen)}
        btnNeutro={
          modalText == NETWORK_ERRORS.USER_NOT_CONFIRMED
            ? "Activar cuenta"
            : "Entendido"
        }
        handleNeutro={() => {
          setInProgress(false);
          setModalText("");
          setTextInError("");
          if (resError.msg == NETWORK_ERRORS.USER_NOT_CONFIRMED) {
            setNav(MENU_OPTION.ACTIVATION);
          }
        }}
      />
    </>
  );
}
