import axios from "axios";
import { getStore } from "../redux/_store/index";
import env from "../configs/Ambiente";
const { url_services } = require("../configs/Ambiente.json")[env];

export const axiosService = async ({
  type,
  url,
  body = null,
  responseType = "json",
  success = null,
  fail = null,
}) => {
  const { token } = getStore().getState().auth;
  if (token) {
    await axios({
      method: type,
      url: url_services + url,
      data: body,
      responseType,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        return success(res);
      })
      .catch((err) => {
        return fail(err);
      });
  }
};

export default axiosService;
