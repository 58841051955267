import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import mainReducer from "../_reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = null;
export default function generateStore() {
  store = createStore(mainReducer, composeEnhancers(applyMiddleware(thunk)));
  return store;
}
export const getStore = () => {
  return store;
};
