import { React, useEffect, useState } from "react";
import spinGray from "../assets/static/spin-gray.gif";
import spinWhite from "../assets/static/spin-white.gif";

/**
 *
 * *Propiedades*
 * * color
 *    * white
 *    * transprent
 *    * red
 *    * orange
 *    * yellow
 *    * blue
 *
 * * disable
 *    * Inhablilita el botón
 *
 * * hidden
 *    * oculta el botón
 *
 * * inProgress.
 *    *true / false
 *
 *    * loadingColor
 *        * gray
 *        * white
 *
 * *Funciones*
 *  * onClick()
 *
 */

export default function ProfileCreate(props) {
  const handleClick = (e) => {
    e.preventDefault();
    if (!props.inProgress) {
      if (!props.href) {
        props.onClick(e);
      } else {
        if (props.href.includes("mail")) {
          //console.log("mail to ", props.href);
          window.location.href = props.href;
          window.location.replace(props.href);
        } else {
          window.open(props.href);
        }
      }
    }
  };

  return (
    <button
      className={`bttn bttn--${props.color}${
        props.inProgress ? "--progress" : props.disable ? "--disable" : ""
      }`}
      onClick={(e) => handleClick(e)}
      disabled={props.disable}
      hidden={props.hidden}
    >
      <div className="bttn-content">
        {props.inProgress == true ? (
          <img
            src={props.loadingColor === "gray" ? spinGray : spinWhite}
            className="bttn-loading"
          />
        ) : (
          ""
        )}
        {props.children}
      </div>
    </button>
  );
}
