import { NETWORK_ERRORS } from "./directory";
const isEmpty = (value) => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

const folioFormat = (folio) => {
  const maxChars = 12;
  let itHasChars = folio.toString().length;
  let startFromPositon = maxChars - itHasChars;
  let fullFolio = "";

  for (var pos = 0; pos < maxChars; pos++) {
    if (pos >= startFromPositon) {
      fullFolio += folio.toString()[pos - startFromPositon];
    } else {
      fullFolio += "0";
    }
  }

  return fullFolio;
};

const changeSpecialChars = (str) => {
  if (str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  return "";
};

const validateEdadAnos = (quantity) => {
  if (quantity) {
    if (quantity !== null) {
      if (quantity.match(/^[0-9]+$/)) {
        return quantity >= 0 && quantity <= 100;
      }
    }
  }

  return false;
};

const validateEdadMeses = (quantity) => {
  if (quantity) {
    if (quantity !== null) {
      if (quantity.match(/^[0-9]+$/)) {
        return quantity >= 0 && quantity <= 12;
      }
    }
  }

  return false;
};

const validateEdadDias = (quantity) => {
  if (quantity) {
    if (quantity !== null) {
      if (quantity.match(/^[0-9]+$/)) {
        return quantity >= 0 && quantity <= 31;
      }
    }
  }

  return false;
};

const validateCoagulacion = (quantity) => {
  if (quantity) {
    if (quantity !== null) {
      return true;
    }
  }

  return false;
};

const validateNSS = (nss) => {
  if (nss) {
    if (nss !== null) {
      if (nss.match(/\d/g).length === 11) {
        return nss.match(/^(\d{2})(\d{2})(\d{2})\d{5}$/);
      }
    }
  }

  return false;
};

const validateCP = (cp) => {
  if (cp) {
    return cp.match(/\d/g).length === 5;
  }
  return false;
};

const validatePhone = (phone) => {
  if (phone) {
    return phone.match(/\d/g).length === 10;
  } else {
    return false;
  }
};

const validateIsOnlyNumber = (value) => {
  if (value) {
    return value.match(/^[0-9]+$/);
  }
  return false;
};

const validateIsOnlyString = (value) => {
  if (value) {
    return value.match(/^[a-zA-Z]+$/);
  }
  return false;
};

const validateCurp = (curp) => {
  if (!curp) return true;

  var re =
      /^([A-ZÑ][AEIOUXÁÉÍÓÚ][A-ZÑ]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    validado = curp.match(re);

  if (!validado)
    //Coincide con el formato general?
    return false;

  //Validar que coincida el dígito verificador
  function digitoVerificador(curp17) {
    var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
      lngSuma = 0.0,
      lngDigito = 0.0;
    for (var i = 0; i < 17; i++)
      lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigito = 10 - (lngSuma % 10);
    if (lngDigito == 10) return 0;
    return lngDigito;
  }

  if (validado[2] != digitoVerificador(validado[1])) return false;

  return true; //Validado
};

const validateEmail = (email) => {
  return String(email).match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const formatPhoneNumber = (number) => {
  return changeSpecialChars(number).replace(/[^\d]/g, "").replace(/\s/g, "");
};

const formatMaxCharsByLine = (str, maxCharsByLine) => {
  if (str.length > maxCharsByLine) {
    return str.slice(0, maxCharsByLine).concat("...");
  } else {
    return str;
  }
};

const getExtensionRoute = (fileName) => {
  let ext = fileName.split(".").reverse();
  return "." + ext[0];
};

const isDocument = (doc) => {
  if (
    doc.includes("jpeg") ||
    doc.includes("jpg") ||
    doc.includes("png") ||
    doc.includes("gif")
  ) {
    return false;
  } else {
    return true;
  }
};

const catchErrorNetwork = (statusCode, msg) => {
  if (statusCode) {
    if (statusCode >= 100 && statusCode <= 199) {
      return "Respuesta informativa";
    } else if (statusCode >= 300 && statusCode <= 399) {
      return "Problema de redirección";
    } else if (statusCode >= 400 && statusCode <= 499) {
      return "Error en cliente";
    } else if (statusCode >= 400 && statusCode <= 499) {
      return "Error de comunicación con el servidor";
    }
  }
  if (JSON.stringify(msg)) {
    if (msg.toLowerCase().includes(NETWORK_ERRORS.IT_WAS_CONFIRMED)) {
      return "Su cuenta ya fue confirmada";
    } else if (msg.toLowerCase().includes(NETWORK_ERRORS.NOT_CONFIRMED)) {
      return "Usuario no confirmado";
    } else if (msg.toLowerCase().includes(NETWORK_ERRORS.USER_EXIST)) {
      return "El usuario ya existe, verifique el correo electrónico y el número de teléfono";
    } else if (msg.toLowerCase().includes(NETWORK_ERRORS.AUTH)) {
      return "Problema en credenciales";
    } else if (msg.toLowerCase().includes(NETWORK_ERRORS.INVALID_PASSWORD)) {
      return "Password inválido";
    } else if (msg.toLowerCase().includes(NETWORK_ERRORS.EXCEPTION)) {
      return "Error al ejecutar proceso";
    }
  }
  return "Problema de conexión, intente más tarde";
};

const getSpecificDate = () => {
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();
  return datetime;
};

export {
  formatDate,
  formatPhoneNumber,
  formatMaxCharsByLine,
  changeSpecialChars,
  validateEdadAnos,
  validateEdadMeses,
  validateEdadDias,
  validateCoagulacion,
  validateNSS,
  validateCP,
  validateCurp,
  validateEmail,
  validatePhone,
  validateIsOnlyNumber,
  validateIsOnlyString,
  isDocument,
  getExtensionRoute,
  folioFormat,
  catchErrorNetwork,
  isEmpty,
  getSpecificDate,
};
