import {
  SHOW_TEXT,
  RESPONSE_ERROR,
  BLEEDING_EDITED,
  BLEEDING_CREATED,
  BLEEDING_SEARCHED,
  BLEEDING_CURRENT,
  BLEEDING_SEARCH_REALIZED,
} from "../_types/bleedingType";

const initialState = {
  bleedingEdited: false,
  bleedingCreated: false,
  bleedingSearched: [],
  bleedingCurrent: [],
  showText: "",
  responseError: false,
  bleedingSearchRealized: false,
};

export default function bleedingReducer(state = initialState, action) {
  switch (action.type) {
    case BLEEDING_EDITED:
      return { ...state, bleedingEdited: action.payload };
    case BLEEDING_CREATED:
      return { ...state, bleedingCreated: action.payload };
    case BLEEDING_SEARCHED:
      return { ...state, bleedingSearched: action.payload };
    case BLEEDING_CURRENT:
      return { ...state, bleedingCurrent: action.payload };
    case SHOW_TEXT:
      return { ...state, showText: action.payload };
    case RESPONSE_ERROR:
      return { ...state, responseError: action.payload };
    case BLEEDING_SEARCH_REALIZED:
      return { ...state, bleedingSearchRealized: action.payload };
    default:
      return { ...state };
  }
}
