import { combineReducers } from "redux";
import authReducer from "./authReducer";
import catalogsReducer from "./catalogsReducer";
import navReducer from "./navReducer";
import usersReducer from "./usersReducers";
import documentsReducer from "./documentsReducer";
import reporteDinamicoReducer from "./reporteDinamicoReducer";
import paginationReducer from "./paginationReducer";
import bleedingReducer from "./bleedingReducer";
import medicationReducer from "./medicationReducer";

const appReducer = combineReducers({
  auth: authReducer,
  pagination: paginationReducer,
  catalogs: catalogsReducer,
  nav: navReducer,
  users: usersReducer,
  docs: documentsReducer,
  reporte: reporteDinamicoReducer,
  bleeding: bleedingReducer,
  medication: medicationReducer,
});

export default appReducer;
