import { PAGINATION, PAGE, TOTAL, LIMIT } from "../_types/paginationType";

const initialState = {
  page: 1,
  total: 0,
  limit: 50,
};

export default function paginationReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE:
      return { ...state, page: action.payload };
    case TOTAL:
      return { ...state, total: action.payload };
    case LIMIT:
      return { ...state, limit: action.payload };
    case PAGINATION:
      const { page, total } = action.payload;
      return { ...state, page, total };
    default:
      return { ...state };
  }
}
