import { React, useEffect, useState } from "react";

const CheckItem = (props) => {
  const [data, setData] = useState(null);

  const updateValueOf = (id, value, checked) => {
    props.onClick(id, value, !checked);
  };

  useEffect(() => {
    if (props) setData(props);
  }, [props]);

  return (
    data && (
      <p
        key={data.i + data.name}
        className={"chk"}
        onClick={(e) => {
          e.preventDefault();
          updateValueOf(data.name, data.value, data.selected);
        }}
      >
        <label>
          <input
            name={data.name || ""}
            value={data.value || ""}
            type="checkbox"
            checked={data.selected}
            readOnly
          />
          <span>{`${data.children || ""}`}</span>
        </label>
      </p>
    )
  );
};

export default CheckItem;
