import {
  ISLOGGED,
  USER,
  TOKEN,
  ACCOUNT_CONFIRMED,
  RES_ERROR,
  GET_CODE_RESET,
} from "../_types/authType";
import { Auth } from "aws-amplify";
import { setReqDirectories } from "./catalogsAction";
import { createUserFinished, checkRolUser } from "./usersAction";
import { catchErrorNetwork } from "../../util/stringer";

export const setShowText = (text) => async (dispatch) => {
  dispatch({ type: RES_ERROR, payload: text });
};

export const getUser = () => async (dispatch) => {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      if (user) {
        dispatch({ type: USER, payload: user });
        dispatch({ type: ISLOGGED, payload: true });
        dispatch({
          type: TOKEN,
          payload: user.signInUserSession.idToken.jwtToken,
        });
        dispatch(setReqDirectories(true));
      } else {
        getUserPool();
      }
    });
  } catch (error) {
    dispatch({ type: USER, payload: "" });
    dispatch({ type: ISLOGGED, payload: false });
    dispatch({ type: TOKEN, payload: "" });
    dispatch({
      type: RES_ERROR,
      payload: {
        isError: false,
        msg: catchErrorNetwork(null, error?.name || error?.message),
      },
    });
  }
};

export const getUserPool = () => async (dispatch) => {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      console.log("user", user);
      if (user) {
        dispatch({ type: USER, payload: user });
        dispatch({ type: ISLOGGED, payload: true });
        dispatch({
          type: TOKEN,
          payload: user.signInUserSession.idToken.jwtToken,
        });
        dispatch(setReqDirectories(true));
      } else {
        dispatch({ type: USER, payload: "" });
        dispatch({ type: ISLOGGED, payload: false });
        dispatch({ type: TOKEN, payload: "" });
      }
    });
  } catch (error) {
    dispatch({ type: USER, payload: "" });
    dispatch({ type: ISLOGGED, payload: false });
    dispatch({ type: TOKEN, payload: "" });
  }
};

export const signIn = (info) => async (dispatch) => {
  try {
    const { user, password } = info;
    await Auth.signIn(user, password)
      .then(async (res) => {
        if (res.username) {
          await Auth.currentAuthenticatedUser({
            bypassCache: true,
          }).then((userPool) => {
            dispatch({
              type: TOKEN,
              payload: userPool.signInUserSession.idToken.jwtToken,
            });
            dispatch({ type: USER, payload: res });
            dispatch(checkRolUser(userPool.username));
          });
        } else {
          dispatch(signInFinalize(null, "No encontro usuario"));
        }
      })
      .catch((error) => {
        dispatch(signInFinalize(null, error));
      });
  } catch (error) {
    dispatch({ type: ISLOGGED, payload: false });
    dispatch({ type: USER, payload: "" });
    dispatch({ type: TOKEN, payload: "" });
    dispatch({
      type: RES_ERROR,
      payload: {
        isError: true,
        msg: catchErrorNetwork(null, error?.name || error?.message),
      },
    });
  }
};

export const signInFinalize = (user, msg) => async (dispatch) => {
  try {
    if (user) {
      dispatch({ type: ISLOGGED, payload: true });

      dispatch({
        type: RES_ERROR,
        payload: { isError: false, msg: "Success" },
      });

      dispatch(setReqDirectories(true));
    } else {
      await Auth.signOut();
      dispatch({ type: ISLOGGED, payload: false });
      dispatch({ type: TOKEN, payload: "" });
      dispatch({ type: USER, payload: "" });

      dispatch({
        type: RES_ERROR,
        payload: {
          isError: true,
          msg: catchErrorNetwork(null, msg),
        },
      });
    }
  } catch (error) {
    await Auth.signOut();
    dispatch({ type: ISLOGGED, payload: false });
    dispatch({ type: TOKEN, payload: "" });
    dispatch({ type: USER, payload: "" });

    dispatch({
      type: RES_ERROR,
      payload: {
        isError: true,
        msg: catchErrorNetwork(null, error?.name || error?.message),
      },
    });
  }
};

export const signOut = () => async (dispatch) => {
  try {
    await Auth.signOut()
      .then(async (res) => {
        dispatch({ type: ISLOGGED, payload: false });
        dispatch({ type: TOKEN, payload: "" });
        localStorage.clear();
        //await DataStore.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const signUp = (name, email, phone, results) => async (dispatch) => {
  const password = "PaWrd#84*";
  try {
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email,
        name,
        phone_number: `+52${phone}`,
      },
    })
      .then((res) => {
        const message = `fue enviado un ${res?.codeDeliveryDetails?.DeliveryMedium} a ${res?.codeDeliveryDetails?.Destination} para verificar la cuenta`;
        dispatch(createUserFinished(true, results, message));
      })
      .catch((error) => {
        dispatch(
          createUserFinished(
            false,
            results,
            catchErrorNetwork(null, error?.name || error?.message)
          )
        );
      });
  } catch (error) {
    dispatch(createUserFinished(false, results, error));
  }
};

export const confirmSignUp = (info) => async (dispatch) => {
  try {
    const { username, code } = info;
    await Auth.confirmSignUp(username, code)
      .then(async (res) => {
        dispatch({ type: ACCOUNT_CONFIRMED, payload: res });
        dispatch({
          type: RES_ERROR,
          payload: { isError: false, msg: "Success" },
        });
      })
      .catch((error) => {
        dispatch({ type: ACCOUNT_CONFIRMED, payload: "" });
        dispatch({
          type: RES_ERROR,
          payload: {
            isError: true,
            msg: catchErrorNetwork(null, error?.message),
          },
        });
      });
  } catch (error) {
    dispatch({ type: ACCOUNT_CONFIRMED, payload: "" });
    dispatch({
      type: RES_ERROR,
      payload: {
        isError: true,
        msg: catchErrorNetwork(null, error?.name || error?.message),
      },
    });
  }
};

export const forgotPassword =
  ({ username, code, newPassword }) =>
  async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(
        username.trim(),
        code.trim(),
        newPassword.trim()
      )
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          //console.log(err);
          dispatch({
            type: RES_ERROR,
            payload: { isError: true, msg: err },
          });
        });
    } catch (error) {
      console.log(error);
      dispatch({
        type: RES_ERROR,
        payload: { isError: true, msg: error },
      });
    }
  };

export const getCodeReset =
  ({ username }) =>
  async (dispatch) => {
    try {
      if (!username) return dispatch({ type: GET_CODE_RESET, payload: false });
      Auth.forgotPassword(username)
        .then((data) => {
          let { DeliveryMedium, Destination } = data.CodeDeliveryDetails;
          dispatch({ type: GET_CODE_RESET, payload: true });
          dispatch({
            type: RES_ERROR,
            payload: {
              isError: false,
              msg: `Se envío ${DeliveryMedium} a ${Destination}`,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GET_CODE_RESET, payload: false });
          dispatch({
            type: RES_ERROR,
            payload: { isError: true, msg: err },
          });
        });
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CODE_RESET, payload: false });
      dispatch({
        type: RES_ERROR,
        payload: { isError: true, msg: error },
      });
    }
  };
