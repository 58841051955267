import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentView } from "../redux/_actions/navAction";

const NavContext = createContext();

const NavProvider = ({ children }) => {
  const { currentView, folio } = useSelector((state) => state.nav);
  const dispatch = useDispatch();

  const setView = (view) => {
    dispatch(setCurrentView(view));
  };

  const data = {
    currentView,
    setView,
  };
  return <NavContext.Provider value={data}>{children}</NavContext.Provider>;
};

export { NavProvider };
export default NavContext;
