import { useEffect, useState } from "react";
import icArrowRed from "../assets/static/ic_arrow_red_down.svg";
import waDinamic from "../assets/static/wa_black_dinamic.png";
import Button from "./Button";
import {
  formatPhoneNumber,
  formatMaxCharsByLine,
  formatDate,
  folioFormat,
  validateEmail,
  isEmpty,
} from "../util/stringer";

function Profile(props) {
  const charEmpty = "  ";
  const [isOpen, setIsOpen] = useState(false);
  let sanitizedName = !isEmpty(props.name) && props.name.trim();
  let sanitizedPhone = formatPhoneNumber(props.phone) || charEmpty;

  return (
    <div className="profile-item">
      <div className={`profile-data profile-data--${props.columns}`}>
        {props.folio ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className={`profile-title`}>ID</p>
            <p className={`profile-value`}>{folioFormat(props.folio)}</p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}

        {props.fecha ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className="profile-title">Fecha</p>
            <p className="profile-value">
              {formatDate(props.fecha) || charEmpty}
            </p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}

        {props.hora ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className="profile-title">Hora</p>
            <p className="profile-value">{props.hora || charEmpty}</p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}

        {props.motivo ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className="profile-title">Motivo</p>
            <p className="profile-value">{props.motivo.trim() || charEmpty}</p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}

        {props.detalle ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className="profile-title">Detalle</p>
            <p className="profile-value">{props.detalle.trim() || charEmpty}</p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}

        {props.aplicacion ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.handleClick(props.folio);
            }}
          >
            <p className="profile-title">Aplicación</p>
            <p className="profile-value">
              {props.aplicacion === 1 ? "Medicamento aplicado" : charEmpty}
            </p>
          </div>
        ) : (
          <div className={`profile--emptyField`}>{charEmpty}</div>
        )}
      </div>
      <div className="profile-arrow">
        <img
          src={icArrowRed}
          className={`profile-arrow-img--${isOpen ? "open" : "close"}`}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        />
      </div>
    </div>
  );
}

export default Profile;
