import { React, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileItem from "../../components/ProfileItem";
import Button from "../../components/Button";
import LayoutMain from "../LayoutMain";
import Pagination from "../../components/Pagination";
import icFilter from "../../assets/static/ic_filter.svg";
import icFinder from "../../assets/static/ic_finder.svg";
import { useDispatch } from "react-redux";
import AuthContext from "../../context/AuthContext";
import NavContext from "../../context/NavContext";
import { MENU_OPTION } from "../../util/directory";
import LoaderPage from "../../components/LoaderPage";
import {
  getMedicationSearched,
  setSerchRealized,
} from "../../redux/_actions/medicationAction";
import { onChangePage } from "../../redux/_actions/paginationAction";

export default function ProfileList() {
  let navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [searching, setSearching] = useState("");
  const dispatch = useDispatch();
  const { setView } = useContext(NavContext);
  const [renderPage, setRenderPage] = useState(false);
  const { isLogged, user } = useContext(AuthContext);
  const [isNewPage, setIsNewPage] = useState(false);
  let {
    medicationSearched,
    medicationSearchRealized,
    showText,
    responseError,
  } = useSelector((state) => state.medication);
  const { page, total, limit } = useSelector((state) => state.pagination);

  const setNav = (opt) => {
    setView(opt);
    navigate(opt);
  };

  const onHandleCreate = () => {
    setNav(MENU_OPTION.MEDICATION_ID + btoa("creation"));
  };

  const onHandleEdit = (idUsuario) => {
    setNav(MENU_OPTION.MEDICATION_ID + btoa(idUsuario));
  };

  const handleKeyDown = (e) => {
    if (e.charCode === 13) {
      handleSearchLauncher();
    }
  };

  const handleSearching = (e) => {
    e.preventDefault();
    setSearching(e.target.value);
  };

  const onHandleChangePage = (toPage) => {
    dispatch(onChangePage(toPage));
    setIsNewPage(true);
  };

  const getData = () => {
    setLoadingData(true);
    let id_usuario = user.attributes ? user.attributes.email : "";
    dispatch(getMedicationSearched(searching, page, limit, id_usuario));
  };

  const handleSearchLauncher = () => {
    getData();
  };

  useEffect(() => {
    if (page && isNewPage) {
      setIsNewPage(false);
      getData(); //Este se ejecuta desde entrada interna
    }
  }, [page]);

  useEffect(() => {
    if (medicationSearchRealized) {
      dispatch(setSerchRealized(false));
      setLoadingData(false);
    }
  }, [medicationSearchRealized]);

  useEffect(() => {
    if (isLogged) {
      setRenderPage(true);
    }
  }, [isLogged]);

  useEffect(() => {
    if (renderPage && isLogged && !medicationSearchRealized) {
      setView(MENU_OPTION.MEDICATION);
      setRenderPage(true);
      getData();
    }
  }, [renderPage]);

  const loaderCount = (num, maxCounter) => {
    if (num >= maxCounter) {
      setNav(MENU_OPTION.LOGIN);
    }
  };

  return !renderPage ? (
    <LoaderPage action={loaderCount} />
  ) : (
    <LayoutMain>
      <div className="container-body">
        <div className="container-filter">
          <div className="filter-combo">
            <img className="filter-icon" src={icFilter} />
            <p className="filter-text">Filtrar</p>
          </div>
          <input
            className="filter-inpt"
            placeholder="Buscar"
            value={searching}
            onKeyPress={(e) => handleKeyDown(e)}
            onChange={(e) => handleSearching(e)}
          />
          <img
            className="filter-search"
            src={icFinder}
            onClick={(e) => {
              e.preventDefault();
              handleSearchLauncher();
            }}
          />
        </div>
        <div className="container-title">
          <h3 className="title-left">Lista de aplicaciones medicas</h3>
          <div className="btn-right">
            <Button
              color="accent-color"
              onClick={(e) => {
                onHandleCreate(e);
              }}
            >
              {"Administrar medicamento"}
            </Button>
          </div>
        </div>
        {loadingData ? (
          !responseError ? (
            <LoaderPage />
          ) : (
            <div className="profile-empty">
              <label>{`${showText}`}</label>
            </div>
          )
        ) : medicationSearched.length == 0 ? (
          <div className="profile-empty">
            <label>
              {responseError
                ? `${showText} \n intenta más tarde`
                : "Busca la administración del medicamento por motivo"}
            </label>
          </div>
        ) : (
          <>
            <Pagination
              totalCount={total}
              currentPage={page}
              siblingCount={1}
              pageSize={limit}
              onPageChange={(page) => onHandleChangePage(page)}
            />

            <div className="profile-header profile-header--4">
              <p>ID</p>
              <p>Fecha</p>
              <p>Hora</p>
              <p>Motivo</p>
            </div>
            <div className="profile-list">
              {medicationSearched?.map(
                (
                  { id_medicacion_persona_clinica, fecha, hora, motivo },
                  id
                ) => {
                  return (
                    <ProfileItem
                      key={id}
                      folio={id_medicacion_persona_clinica}
                      fecha={fecha}
                      hora={hora}
                      motivo={motivo}
                      columns={4}
                      handleClick={onHandleEdit}
                    />
                  );
                }
              )}
            </div>

            <Pagination
              totalCount={total}
              currentPage={page}
              siblingCount={1}
              pageSize={limit}
              onPageChange={(page) => onHandleChangePage(page)}
            />
          </>
        )}
      </div>
    </LayoutMain>
  );
}
