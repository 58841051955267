import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function LayoutMain(props) {
  return (
    <div className="container">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}
