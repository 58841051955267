import {
  SHOW_TEXT,
  RESPONSE_ERROR,
  MEDICATION_EDITED,
  MEDICATION_CREATED,
  MEDICATION_SEARCHED,
  MEDICATION_CURRENT,
  MEDICATION_SEARCH_REALIZED,
} from "../_types/medicationType";

const initialState = {
  medicationEdited: false,
  medicationCreated: false,
  medicationSearched: [],
  medicationCurrent: [],
  showText: "",
  responseError: false,
  medicationSearchRealized: false,
};

export default function medicationReducer(state = initialState, action) {
  switch (action.type) {
    case MEDICATION_EDITED:
      return { ...state, medicationEdited: action.payload };
    case MEDICATION_CREATED:
      return { ...state, medicationCreated: action.payload };
    case MEDICATION_SEARCHED:
      return { ...state, medicationSearched: action.payload };
    case MEDICATION_CURRENT:
      return { ...state, medicationCurrent: action.payload };
    case SHOW_TEXT:
      return { ...state, showText: action.payload };
    case RESPONSE_ERROR:
      return { ...state, responseError: action.payload };
    case MEDICATION_SEARCH_REALIZED:
      return { ...state, medicationSearchRealized: action.payload };
    default:
      return { ...state };
  }
}
