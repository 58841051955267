import {
  SHOW_TEXT,
  USER_EDITED,
  USER_CREATED,
  USERS_SEARCHED,
  USER_CURRENT,
  SEARCH_REALIZED,
  RESPONSE_ERROR,
} from "../_types/usersTypes";

const initialState = {
  userEdited: false,
  userCreated: false,
  usersSearched: [],
  userCurrent: [],
  showText: "",
  responseError: false,
  searchRealized: false,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case USER_EDITED:
      return { ...state, userCreated: action.payload };
    case USER_CREATED:
      return { ...state, userCreated: action.payload };
    case USERS_SEARCHED:
      return { ...state, usersSearched: action.payload };
    case USER_CURRENT:
      return { ...state, userCurrent: action.payload };
    case SHOW_TEXT:
      return { ...state, showText: action.payload };
    case RESPONSE_ERROR:
      return { ...state, responseError: action.payload };
    case SEARCH_REALIZED:
      return { ...state, searchRealized: action.payload };
    default:
      return { ...state };
  }
}
